import PropTypes from "prop-types";
// @mui
import { Box, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
// utils
import { fCurrency } from "../../../../../utils/formatNumber";
// components
import Iconify from "../../../../../components/Iconify";
import { IncrementerButton } from "../../../../../components/custom-input";
import LazyImage from "../../../../../components/image/LazyImage";

// ----------------------------------------------------------------------

CheckoutCartProduct.propTypes = {
  row: PropTypes.object,
  onDelete: PropTypes.func,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func
};

export default function CheckoutCartProduct({ row, onDelete, onDecrease, onIncrease }) {
  const { name, price, images, lager, quantity } = row;

  return (
    <TableRow>
      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <LazyImage
          alt={name}
          url={images === undefined ? undefined : images[0]}
          sx={{ width: 64, height: 64, borderRadius: 1.5, mr: 2 }}
        />

        <Stack spacing={0.5}>
          <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{fCurrency(price)}</TableCell>

      <TableCell>
        <Box sx={{ width: 96, textAlign: "right" }}>
          <IncrementerButton
            quantity={quantity}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            disabledDecrease={quantity <= 1}
            disabledIncrease={quantity >= lager}
          />

          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            Dostupno: {lager}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="right">{fCurrency(price * quantity)}</TableCell>

      <TableCell align="right">
        <IconButton onClick={onDelete}>
          <Iconify icon="eva:trash-2-outline" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
