import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, InputAdornment, Stack, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/path";
// components
import { useSnackbar } from "notistack";
import FormProvider, { RHFEditor, RHFSwitch, RHFTextField, RHFUpload } from "../../../../components/hook-form";
import { dispatch } from "../../../../redux/store";
import {
  insertNewSaleProduct,
  removeSaleProductImage,
  updateSaleProduct,
  uploadSaleProductImages
} from "../../../../redux/slices/products";
import "react-quill/dist/quill.snow.css";
import ConfirmDialog from "../../../../components/confirm-dialog";

ProductNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentProduct: PropTypes.object
};

export default function ProductNewEditForm({ isEdit, currentProduct }) {
  const navigate = useNavigate();
  const [confirmDialogProps, setConfirmDialogProps] = useState({
    open: false,
    image: undefined
  });
  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required("Polje je obavezno"),
    description: Yup.string().required("Polje je obavezno"),
    images: Yup.array().min(1, "Morate dodati minimum 1 sliku"),
    price: Yup.number().moreThan(0, "Cena mora biti veća od 0.00")
  });

  const defaultValues = useMemo(
    () => ({
      uid: currentProduct?.uid || "",
      name: currentProduct?.name || "",
      price: currentProduct?.price || 0,
      description: currentProduct?.description || "",
      discount: currentProduct?.discount || 0,
      lager: currentProduct?.lager || 0,
      images: currentProduct?.images || [],
      active: currentProduct?.active === undefined ? false : currentProduct?.active,
      createdAt: currentProduct?.createdAt || {}
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProduct]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentProduct) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProduct]);

  const onSubmit = async (data) =>
    await new Promise((resolve, reject) => {
      if (isEdit) {
        dispatch(updateSaleProduct(data)).unwrap()
          .then(_ => {
            const newImages = data.images.filter(image => typeof image !== "string");
            if (newImages.length > 0) {
              dispatch(uploadSaleProductImages({
                images: newImages,
                uid: data.uid
              })).unwrap().then(_ => {
                enqueueSnackbar("Uspešno ste izmenili proizvod", { variant: "success" });
                navigate(PATH_DASHBOARD.admin.products.root);
                return resolve();
              }).catch(reason => {
                console.error("uploadSaleProductImages", reason);
                enqueueSnackbar("Proizvod izmenjen, ali neke slike nisu uspešno okačene", { variant: "warning" });
                navigate(PATH_DASHBOARD.admin.products.root);
                return resolve();
              });
            } else {
              enqueueSnackbar("Uspešno ste izmenili proizvod", { variant: "success" });
              navigate(PATH_DASHBOARD.admin.products.root);
              return resolve();
            }
          }).catch(reason => {
          console.error("updateSaleProduct", reason);
          return reject(reason);
        });
      } else {
        dispatch(insertNewSaleProduct(data)).unwrap()
          .then(response => {
            dispatch(uploadSaleProductImages({
              images: data.images,
              uid: response.key
            })).unwrap().then(_ => {
              enqueueSnackbar("Uspešno ste dodali proizvod", { variant: "success" });
              navigate(PATH_DASHBOARD.admin.products.root);
              return resolve();
            }).catch(reason => {
              console.error("uploadSaleProductImages", reason);
              enqueueSnackbar("Proizvod kreiran, ali neke slike nisu uspešno okačene", { variant: "warning" });
              navigate(PATH_DASHBOARD.admin.products.root);
              return resolve();
            });
          }).catch(reason => {
          console.error("insertNewSaleProduct", reason);
          return reject(reason);
        });
      }
    });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      setValue("images", [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = (inputFile) => {
    if (typeof inputFile === "string") {
      setConfirmDialogProps({
        open: true,
        image: inputFile
      });
    } else {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue("images", filtered);
    }
  };

  const handleCloseConfirm = () => {
    setConfirmDialogProps({
      open: false,
      image: undefined
    });
  };

  const onDeleteImage = (image) => {
    dispatch(removeSaleProductImage({
      image,
      uid: currentProduct.uid
    })).unwrap().then(_ => {
      const filtered = values.images && values.images?.filter((file) => file !== image);
      setValue("images", filtered);
      handleCloseConfirm();
    }).catch(reason => {
      console.error("removeSaleProductImage", reason);
      enqueueSnackbar("Greška prilikom brisanja slike", { variant: "error" });
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="name" label="Naziv proizvoda" />

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                  Opis
                </Typography>

                <RHFEditor simple name="description" />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                  Slike
                </Typography>

                <RHFUpload
                  multiple
                  thumbnail
                  name="images"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  onRemove={handleRemoveFile}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              {isEdit && <RHFSwitch name="active" label="Aktivan?" />}

              <Stack spacing={3} mt={2}>
                <RHFTextField
                  type="number"
                  name="lager" label="Količina na stanju" />

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mb={2}>
                <RHFTextField
                  name="price"
                  label="Cena"
                  placeholder="0.00"
                  onChange={(event) =>
                    setValue("price", Number(event.target.value), { shouldValidate: true })
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: "text.disabled" }}>
                          RSD
                        </Box>
                      </InputAdornment>
                    ),
                    type: "number"
                  }}
                />

                <RHFTextField
                  name="discount"
                  label="Popust"
                  placeholder="0.00"
                  onChange={(event) => setValue("discount", Number(event.target.value))}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: "text.disabled" }}>
                          %
                        </Box>
                      </InputAdornment>
                    ),
                    type: "number"
                  }}
                />
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit ? "Kreiraj proizvod" : "Sačuvaj izmene"}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmDialogProps.open}
        onClose={handleCloseConfirm}
        title="Brisanje slike"
        content="Da li ste sigurni da želite da obrišete sliku?"
        action={
          <Button variant="contained" color="error" onClick={() => onDeleteImage(confirmDialogProps.image)}>
            Obriši
          </Button>
        }
      />
    </FormProvider>
  );
}
