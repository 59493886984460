import PropTypes from "prop-types";
// @mui
import { Stack, Typography } from "@mui/material";
// components
import Logo from "../../components/logo";
import Image from "../../components/image";
//
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from "./styles";

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string
};

export default function LoginLayout({ children, title }) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 }
        }}
      />

      <StyledSection>
        <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: "center" }}>
          {title || "Zdravo, dobrodošli nazad!"}
        </Typography>

        <div style={{
          backgroundColor: "#ddc",
          border: "solid 5vmin #eee",
          borderBottomColor: "#fff",
          borderLeftColor: "#eee",
          borderRadius: "2px",
          borderRightColor: "#eee",
          borderTopColor: "#ddd",
          boxShadow: "0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25)",
          boxSizing: "border-box",
          display: "inline-block",
          margin: "2vh 2vw",
          height: "50vh",
          padding: "3vmin",
          position: "relative",
          textAlign: "center",
          "&:before": {
            borderRadius: "2px",
            bottom: "-2vmin",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.25) inset",
            content: "",
            left: "-2vmin",
            position: "absolute",
            right: "-2vmin",
            top: "-2vmin"
          },
          "&:after": {
            borderRadius: "2px",
            bottom: "-2.5vmin",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
            content: "",
            left: "-2.5vmin",
            position: "absolute",
            right: "-2.5vmin",
            top: "-2.5vmin"
          }
        }}>
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src="https://picsum.photos/600/600"
            sx={{maxWidth :"100%", height: "100%"}}
          />
        </div>

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
