import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";
// slices
import notificationsReducer from "./slices/notifications";
import clientsReducer from "./slices/clients";
import chatReducer from "./slices/chat";
import productsReducer from "./slices/products";
import adminReducer from "./slices/admin";
import ordersReducer from "./slices/orders";
import settingsReducer from "./slices/settings";
import paymentsReducer from "./slices/payments";
import analyticsReducer from "./slices/analytics";
import specificationsReducer from "./slices/specifications";
import nomenclaturesReducer from "./slices/nomenclatures";
import clientPreviewReducer from "./slices/clientPreview";
import tenantsReducer from "./slices/tenants";
import { persistReducer } from 'redux-persist';
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

export const productPersistConfig = {
    key: 'products',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
    notifications: notificationsReducer,
    clients: clientsReducer,
    admin: adminReducer,
    chat: chatReducer,
    orders: ordersReducer,
    products: persistReducer(productPersistConfig,productsReducer),
    settings: settingsReducer,
    payments: paymentsReducer,
    nomenclatures: nomenclaturesReducer,
    clientPreview: clientPreviewReducer,
    analytics: analyticsReducer,
    specifications: specificationsReducer,
    tenants: tenantsReducer,
});

export {rootPersistConfig, rootReducer};
