import PropTypes from "prop-types";
//
import Image from "../../image";

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
    if (!file) {
        return null;
    }

    if(typeof file === 'object'){
        return <div style={{width: 50, height:50, background: 'green'}}></div>
    } else return (
        <Image
            alt="file preview"
            src={file}
            sx={{
                top: 8,
                left: 8,
                zIndex: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
            }}
        />
    );
}
