// ----------------------------------------------------------------------

export function emptyRows(page, rowsPerPage, arrayLength) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function descendingComparator (a, b, orderBy) {
  if (orderBy === 'finance') {
    if (b[orderBy].balance < a[orderBy].balance) {
      return -1
    }
    if (b[orderBy].balance > a[orderBy].balance) {
      return 1
    }
    return 0
  } else if (orderBy === 'Naziv') {
    if (b.details?.payeeinfo?.name < a.details?.payeeinfo?.name) {
      return -1
    }
    if (b.details?.payeeinfo?.name > a.details?.payeeinfo?.name) {
      return 1
    }
    return 0
  } else if (orderBy === 'Model') {
    if (b.details?.payeerefmodel < a.details?.payeerefmodel) {
      return -1
    }
    if (b.details?.payeerefmodel > a.details?.payeerefmodel) {
      return 1
    }
    return 0
  } else if (orderBy === 'Referentni broj') {
    if (b.details?.payeerefnumber < a.details?.payeerefnumber) {
      return -1
    }
    if (b.details?.payeerefnumber > a.details?.payeerefnumber) {
      return 1
    }
    return 0
  } else if (orderBy === 'Svrha') {
    if (b.details?.purpose < a.details?.purpose) {
      return -1
    }
    if (b.details?.purpose > a.details?.purpose) {
      return 1
    }
    return 0
  } else if (orderBy === 'createdAt') {
    if (b[orderBy]?.seconds < a[orderBy]?.seconds) {
      return -1
    }
    if (b[orderBy]?.seconds > a[orderBy]?.seconds) {
      return 1
    }
    return 0
  } else if (orderBy === 'id' && a.hasOwnProperty('uid')) {
    if (b['uid'] < a['uid']) {
      return -1
    }
    if (b['uid'] > a['uid']) {
      return 1
    }
    return 0
  } else if (orderBy === 'note') {
    if (a[orderBy] === undefined) return 1
    if (b[orderBy] === undefined) return -1
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
