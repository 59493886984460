import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { useSelector } from "../../../redux/store";
import { fToNow } from "../../../utils/formatTime";

export default function AllNotificationsDialog({ open, onClose, onNotificationClick }) {
  const { messages } = useSelector((state => state.notifications));

  return (
    <Dialog
      style={{
        minWidth: 300
      }}
      scroll={"paper"}
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Sva obaveštenja
      </DialogTitle>
      <DialogContent dividers={true}>
        <List
          disablePadding>
          {messages.map((notification, index) => (
            <NotificationItem
              onClick={() => onNotificationClick(notification)}
              key={`all-notifications-${index}`}
              notification={notification} />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zatvori</Button>
      </DialogActions>
    </Dialog>
  );
}

function NotificationItem({ notification, onClick }) {
  return (
    <ListItemButton
      to="#"
      onClick={onClick}
      disableGutters
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.isRead && {
          bgcolor: "action.selected"
        })
      }}>
      <ListItemAvatar>
        <Avatar sx={{ objectFit: "contain", bgcolor: "background.neutral" }}>
          <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={notification.title}
        secondary={fToNow(notification.date)}
      />
    </ListItemButton>
  );
}