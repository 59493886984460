import PropTypes from "prop-types";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// assets
import FormProvider, { RHFCheckbox, RHFTextField } from "../../../../../components/hook-form";
import { useAuthContext } from "../../../../../auth/useAuthContext";
import { useEffect } from "react";
import { saveAddress } from "../../../../../helper/session";

// ----------------------------------------------------------------------

CheckoutBillingNewAddressForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateBilling: PropTypes.func
};

export default function CheckoutBillingNewAddressForm({ open, onClose, onCreateBilling }) {
  const { user } = useAuthContext();
  const NewAddressSchema = Yup.object().shape({
    name: Yup.string().required("Polje je obavezno"),
    lastname: Yup.string().required("Polje je obavezno"),
    email: Yup.string().email("Email nije validan").required("Polje je obavezno"),
    phoneNumber: Yup.string().required("Polje je obavezno"),
    address: Yup.string().required("Polje je obavezno"),
    city: Yup.string().required("Polje je obavezno"),
    zipCode: Yup.string().required("Polje je obavezno")
  });

  const defaultValues = {
    name: "",
    lastname: "",
    phoneNumber: user?.phoneNumber,
    email: user.email,
    address: "",
    city: "",
    zipCode: "",
    isDefault: true
  };

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = methods;

  useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (data) => {
    try {
      saveAddress({
        ...data,
        fullAddress: `${data.address}, ${data.city}, ${data.zipCode}`,
      })
      onCreateBilling({
        name: data.name,
        lastname: data.lastname,
        email: data.email,
        phoneNumber: data.phoneNumber,
        fullAddress: `${data.address}, ${data.city}, ${data.zipCode}`,
        isDefault: data.isDefault
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Dodavanje nove adrese</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)"
              }}>
              <RHFTextField name="name" label="Ime" />
              <RHFTextField name="lastname" label="Prezime" />

              <RHFTextField name="phoneNumber" label="Telefon" />
              <RHFTextField name="email" label="Email" />
            </Box>

            <RHFTextField name="address" label="Adresa" />

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(3, 1fr)"
              }}>
              <RHFTextField name="city" label="Grad" />
              <RHFTextField name="zipCode" label="Poštanski broj" />
            </Box>

            <RHFCheckbox name="isDefault" label="Koristi ovu adresu uvek" sx={{ mt: 3 }} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Dostavi na ovu adresu
          </LoadingButton>

          <Button color="inherit" variant="outlined" onClick={onClose}>
            Odustani
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
