import {Helmet} from 'react-helmet-async';
// sections
import Login from './LoginAuth';

// ----------------------------------------------------------------------

export default function LoginPage() {
    return (
        <>
            <Helmet>
                <title> Logovanje | Fiskal BSS</title>
            </Helmet>

            <Login />
        </>
    );
}
