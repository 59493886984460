import Router from "./routes";
import ThemeProvider from "./theme";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import {ThemeSettings} from "./components/settings";
import SnackbarProvider from "./components/snackbar";
import {OFFLINE_DB_NAME} from "./constants/OfflineDb";
import {useEffect} from "react";
import Dexie from "dexie";
import {createDB} from "./store/offlineDb";

function App() {

    useEffect(() => {
        Dexie.exists(OFFLINE_DB_NAME).then(exists => {
            if (!exists) {
                createDB();
            }
        }).catch(reason => {
            console.error(reason)
            window.alert("Nije moguće kreirati bazu podataka!")
        })
    }, [])

    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <SnackbarProvider>
                        <Router/>
                    </SnackbarProvider>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}

export default App;
