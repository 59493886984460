import PropTypes from "prop-types";
// @mui
import { List, Stack } from "@mui/material";
// locales
//
import { StyledSubheader } from "./styles";
import NavList from "./NavList";
import { useAuthContext } from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { role } = useAuthContext();
  return (
    <Stack sx={sx} {...other}>
      {data.filter((item)=> !item.roles || item.roles.includes(role)).map((group) => {
        const key = group.subheader || group.items[0].title;
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

            {group.items.map((list) => (
              <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
