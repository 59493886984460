import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {Container, Typography} from '@mui/material';
import MotionContainer from "../components/animate/MotionContainer";
import {varBounce} from "../components/animate";
import ForbiddenIllustration from "../assets/illustratuions/ForbiddenIllustration";
import { useAuthContext } from "./useAuthContext";
// components


// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
    hasContent: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.number),
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
    // Logic here to get current user role
  const {role} = useAuthContext();

    if (typeof roles !== 'undefined' && !roles.includes(role)) {
        return hasContent ? (
            <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        Permission Denied
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                </m.div>
            </Container>
        ) : null;
    }

    return <>{children}</>;
}
