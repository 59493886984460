import PropTypes from "prop-types";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../../../components/Iconify";
import FormProvider from "../../../../../components/hook-form";
//
import CheckoutSummary from "../CheckoutSummary";
import CheckoutBillingInfo from "./CheckoutBillingInfo";
import CheckoutPaymentMethods from "./CheckoutPaymentMethods";
import { dispatch } from "../../../../../redux/store";
import { createNewOrder } from "../../../../../redux/slices/orders";
import { useSnackbar } from "notistack";
import { addNotificationOrderForAdmin } from "../../../../../redux/slices/notifications";

// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: "cash",
    title: "Keš",
    description: "Plaćanje prilikom dostave",
    icons: []
  }
];

CheckoutPayment.propTypes = {
  onReset: PropTypes.func,
  checkout: PropTypes.object,
  onBackStep: PropTypes.func,
  onGotoStep: PropTypes.func,
  onNextStep: PropTypes.func
};

export default function CheckoutPayment({
                                          checkout,
                                          onReset,
                                          onNextStep,
                                          onBackStep,
                                          onGotoStep
                                        }) {
  const {enqueueSnackbar} = useSnackbar();
  const { total, discount, subtotal, shipping, billing } = checkout;

  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required("Način plaćanja je obavezan!")
  });

  const defaultValues = {
    delivery: shipping,
    payment: ""
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async () => {
    try {
      await dispatch(createNewOrder({
        address: checkout.billing.address,
        city: checkout.billing.city,
        email: checkout.billing.email,
        fullAddress: checkout.billing.fullAddress,
        name: checkout.billing.name,
        lastname: checkout.billing.lastname,
        phoneNumber: checkout.billing.phoneNumber,
        zipCode: checkout.billing.zipCode,
        discount: checkout.discount,
        subtotal: checkout.subtotal,
        total: checkout.total,
        totalItems: checkout.totalItems,
        items: checkout.cart.map(item=> ({
          uid: item.uid,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
        }))
      }))
      try{
        await dispatch(addNotificationOrderForAdmin());
      }catch (e){
        console.error("ERRRR", e)
      }
      onNextStep();
      onReset();
    } catch (error) {
      console.error("createNewOrder", error);
      enqueueSnackbar("Greška prilikom kreiranja porudžbine",{variant: "error"} )
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>

          <CheckoutPaymentMethods
            paymentOptions={PAYMENT_OPTIONS}
            sx={{ my: 3 }}
          />

          <Button
            size="small"
            color="inherit"
            onClick={onBackStep}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}>
            Nazad
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutBillingInfo onBackStep={onBackStep} billing={billing} />

          <CheckoutSummary
            enableEdit
            total={total}
            subtotal={subtotal}
            discount={discount}
            shipping={shipping}
            onEdit={() => onGotoStep(0)}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}>
            Završi porudžbinu
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
