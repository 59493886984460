import {Link as RouterLink} from "react-router-dom";
// @mui
import {alpha, styled} from "@mui/material/styles";
import {Box, Link, Typography} from "@mui/material";
// auth
// routes
// components
import {CustomAvatar} from "../../../components/custom-avatar";
import {PATH_DASHBOARD} from "../../../routes/path";
import {useAuthContext} from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12)
}));

// ----------------------------------------------------------------------

export default function NavAccount() {

    const {user} = useAuthContext();

    return (
        <Link to={PATH_DASHBOARD.general.app} component={RouterLink} underline="none" color="inherit">
            <StyledRoot>
                <CustomAvatar src={user?.photoURL} alt={user.displayName} name={user.displayName}/>

                <Box sx={{ml: 2, minWidth: 0}}>
                    <Typography variant="subtitle2" noWrap>
                        {user.displayName}
                    </Typography>

                    <Typography variant="body2" noWrap sx={{color: "text.secondary"}}>
                        {user.group}
                    </Typography>
                </Box>
            </StyledRoot>
        </Link>
    );
}