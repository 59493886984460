import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Button, IconButton, MenuItem, Stack, TableCell, TableRow } from "@mui/material";
// utils
import { fDate } from "../../../utils/formatTime";
import { fCurrency } from "../../../utils/formatNumber";
// components
import MenuPopover from "../../../components/menu-popover";
import ConfirmDialog from "../../../components/confirm-dialog";
import Iconify from "../../../components/Iconify";
import LazyImage from "../../../components/image/LazyImage";

// ----------------------------------------------------------------------

ProductTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function ProductTableRow({
                                          row,
                                          onDeleteRow,
                                          onEditRow
                                        }) {
  const { name, images, createdAt, active, price, discount, lager } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LazyImage
              sx={{ borderRadius: 1.5, width: 48, height: 48 }}
              url={images === undefined ? undefined : images[0]}
            />
            {name}
          </Stack>
        </TableCell>

        <TableCell>{fDate(new Date(createdAt.seconds * 1000))}</TableCell>

        <TableCell align="center">
          <Iconify
            icon={!active ? "ri:checkbox-blank-circle-line" : "eva:checkmark-circle-fill"}
            sx={{
              width: 20,
              height: 20,
              color: "success.main",
              ...(!active && { color: "error.main" })
            }}
          />
        </TableCell>
        <TableCell align="center">{lager}</TableCell>
        <TableCell align="right">{fCurrency(`${price}`)}</TableCell>
        <TableCell align="right">{fCurrency(`${discount}`)}%</TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? "primary" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}>
          <Iconify icon="eva:trash-2-outline" />
          Obriši
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}>
          <Iconify icon="eva:edit-fill" />
          Izmeni
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Brisanje proizvoda"
        content="Da li ste sigurni da želite da obrišete proizvod?"
        action={
          <Button variant="contained" color="error" onClick={() => onDeleteRow(row, handleCloseConfirm)}>
            Obriši
          </Button>
        }
      />
    </>
  );
}
