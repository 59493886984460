import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { collection, doc, getDocs, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";

export const fetchAllUserRooms = createAsyncThunk("fetchAllUserRooms", async () => {
  let arr = [];
  const q = query(collection(DB, `chat/rooms/bss`), where("participants", "array-contains", AUTH.currentUser.uid));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return arr;
});

export const fetchAllMessagesByRoom = createAsyncThunk("fetchAllMessagesByRoom", async (roomId) => {
  let arr = [];
  const q = query(collection(DB, `chat/messages/bss`), where("roomId", "==", roomId));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return arr;
});

export const sendMessage = createAsyncThunk("sendMessage", async (data) => {
  const { roomId, receiver, content } = data;
  const newMessageId = Date.now();
  const message = {
    content,
    receiver,
    roomId: roomId,
    sender: AUTH.currentUser.uid,
    time: Timestamp.fromMillis(newMessageId)
  };
  await setDoc(doc(DB, "chat", "messages", "bss", newMessageId.toString()), message);
  const room = {
    lastMessage: {
      content: content,
      sender: AUTH.currentUser.uid
    },
    lastMessageTime: Timestamp.fromMillis(newMessageId),
    unreadCount: 1
  };
  await updateDoc(doc(DB, "chat", "rooms", "bss", roomId.toString()), room);
  return {
    message: {
      ...message,
      id: newMessageId
    },
    room: {
      ...room,
      id: roomId
    }
  };
});

export const sendNewMessage = createAsyncThunk("sendNewMessage", async (data) => {
  const { receiver, details, content } = data;
  let id = Date.now();
  const message = {
    content,
    receiver,
    roomId: id.toString(),
    sender: AUTH.currentUser.uid,
    time: Timestamp.fromMillis(id)
  };
  await setDoc(doc(DB, "chat", "messages", "bss", id.toString()), message);
  let room = {
    lastMessage: {
      content: content,
      sender: AUTH.currentUser.uid
    },
    details,
    lastMessageTime: Timestamp.fromMillis(id),
    participants: [AUTH.currentUser.uid, receiver],
    unreadCount: 1
  };
  await setDoc(doc(DB, "chat", "rooms", "bss", id.toString()), room);
  return {
    message: {
      ...message,
      id: id
    },
    room: {
      ...room,
      id: id
    }
  };
});

export const setReadMessagesForRoom = createAsyncThunk("setReadMessagesForRoom", async (roomId) => {
  await updateDoc(doc(DB, "chat", "rooms", "bss", roomId.toString()), {
    unreadCount: 0
  });
});

const initialState = {
  messages: [],
  rooms: [],
  loadingRooms: false,
  loadingMessages: false,
  sendingMessage: false
};

export const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      const messages = [...current(state.messages)];
      const index = messages.findIndex(m => m.id === payload.id);
      if (index === -1) {
        messages.push(payload);
        state.messages = messages;
      }
    },
    addRoom: (state, { payload }) => {
      const rooms = [...current(state.rooms)];
      const index = rooms.findIndex(r => r.id === payload.id);
      if (index === -1) {
        rooms.push(payload);
        state.rooms = rooms;
      }
    },
    updateRoom: (state, { payload }) => {
      const rooms = [...current(state.rooms)];
      if (payload) {
        const index = rooms.findIndex(r => r.id === payload.id);
        if (index !== -1) {
          rooms[index] = payload;
          state.rooms = rooms;
        }
      }
    }
  },
  extraReducers: {
    [fetchAllUserRooms.pending]: (state) => {
      state.loadingRooms = true;
    },
    [fetchAllUserRooms.fulfilled]: (state, { payload }) => {
      state.rooms = payload;
      state.loadingRooms = false;
    },
    [fetchAllUserRooms.rejected]: (state) => {
      state.loadingRooms = false;
    },
    // fetchAllMessagesByRoom
    [fetchAllMessagesByRoom.pending]: (state) => {
      state.loadingMessages = true;
    },
    [fetchAllMessagesByRoom.fulfilled]: (state, { payload }) => {
      state.messages = payload;
      state.loadingMessages = false;
    },
    [fetchAllMessagesByRoom.rejected]: (state) => {
      state.loadingMessages = false;
    },
    // sendMessage
    [sendMessage.pending]: (state) => {
      state.sendingMessage = true;
    },
    [sendMessage.fulfilled]: (state, { payload }) => {
      const { message, room } = payload;
      const rooms = [...current(state.rooms)];
      const roomIndex = rooms.indexOf(r => r.id === message.roomId);
      if (roomIndex !== -1) {
        rooms[roomIndex] = {
          ...room,
          unreadCount: 0
        };
        state.rooms = rooms;
      }
      const messages = [...current(state.messages)];
      messages.push(message);
      state.messages = messages;
      state.sendingMessage = false;
    },
    [sendMessage.rejected]: (state) => {
      state.sendingMessage = false;
    },
    // sendNewMessage
    [sendNewMessage.pending]: (state) => {
      state.sendingMessage = true;
    },
    [sendNewMessage.fulfilled]: (state, { payload }) => {
      const { message } = payload;
      const messages = [...current(state.messages)];
      const index = messages.findIndex(m => m.id === message.id);
      if (index === -1) {
        messages.push(message);
        state.messages = messages;
      }
      state.sendingMessage = false;
    },
    [sendNewMessage.rejected]: (state) => {
      state.sendingMessage = false;
    },
    [setReadMessagesForRoom.fulfilled]: (state, { meta }) => {
      const rooms = [...current(state.rooms)];
      const roomIndex = rooms.indexOf(r => r.id === meta.arg);
      if (roomIndex !== -1) {
        rooms[roomIndex] = {
          ...rooms[roomIndex],
          unreadCount: 0
        };
        state.rooms = rooms;
      }
    }
  }
});

export const { addMessage, addRoom, updateRoom } = slice.actions;
export default slice.reducer;


