import { child, get } from "firebase/database"
import { dbRef } from "../auth/FirebaseContext"

const TENANT_PERCENTAGE = 0.3 // 30%

const getYearMonthIdentifier = date => {
  return (
    ('0' + date.getYear()).slice(-2) + ('0' + (date.getMonth() + 1)).slice(-2)
  )
}

// Get timestamp in seconds for given contract date
const getTSfromContractID = contractID => {
  let d = new Date(
    contractID.substring(6, 10) +
      '-' +
      contractID.substring(10, 12) +
      '-' +
      contractID.substring(12, 14) +
      'T' +
      contractID.substring(14, 16) +
      ':' +
      contractID.substring(16, 18) +
      ':' +
      contractID.substring(18, 20) +".000Z"
  )
  return d.getTime() / 1000
}

const generateMonthlyInvoiceRefNumber = (contractID, date, suffix) => {
  let ret = {
    refNumber: ''
  }

  // Remove hyphenation and century
  let refNum =
    contractID.substring(0, 2) +
    contractID.substring(3, 5) +
    getTSfromContractID(contractID)

  // Add year and month
  let invoiceId = getYearMonthIdentifier(date)
  ret.refNumber = refNum + '-' + invoiceId
  refNum += invoiceId

  if (suffix !== undefined && suffix !== '') {
    refNum += suffix
    ret.refNumber += `-${suffix}`
  }
  return ret
}

export function tryGenerateNextInvoiceRefNumber (
  contractID,
  processingDate,
  userInvoices
) {
  var refNumber = generateMonthlyInvoiceRefNumber(contractID, processingDate)
  var suffix = 0
  var refFound = userInvoices[refNumber.refNumber] === undefined

  while (!refFound && suffix < 10) {
    refNumber = generateMonthlyInvoiceRefNumber(
      contractID,
      processingDate,
      suffix.toString()
    )
    refFound = userInvoices[refNumber.refNumber] === undefined
    suffix += 1
  }

  if (!refFound) {
    console.error(`Ref number design limitation exceeded.`, refNumber)
    return null
  } else return refNumber
}

export async function getInvoiceAmount(inv) {
  if (inv === undefined) {
    console.error('Calling getInvoiceAmount with undefined')
    return
  }

  let invoiceAmount = 0
  let tenantAmount = 0
  var contractTemplates = (await get(child(dbRef, `bss/public/contractTemplates`))).val()
  var contractTemplatesEF = (await get(child(dbRef, `bss/public/ContractTemplatesEFakt`))).val()

  if (inv.contractTypes !== undefined && inv.contractTypes.esir !== undefined) {
    await Promise.all(
      contractTemplates[inv.contractTypes.esir].invoiceDetails.items.map(
        (v, i) => {
          invoiceAmount += parseFloat(v.unitPrice) * inv.itemQuantity[i]
          tenantAmount += v.unitPrice * v.share * TENANT_PERCENTAGE * inv.itemQuantity[i]
          return true
        }
      )
    )
  }
  if (inv.contractTypes !== undefined && inv.contractTypes.ef !== undefined) {
    await Promise.all(
      contractTemplatesEF[inv.contractTypes.ef].invoiceDetails.items.map(
        (v, i) => {
          invoiceAmount += parseFloat(v.unitPrice) * inv.itemQuantity[i]
          tenantAmount += v.unitPrice * v.share * TENANT_PERCENTAGE * inv.itemQuantity[i]
          return true
        }
      )
    )
  }

  // Check if any value is NaN and throw error
  if (isNaN(parseFloat(invoiceAmount)) || isNaN(parseFloat(tenantAmount))) {
    return null
  }

  inv.amount = invoiceAmount
  inv.tenantAmount = tenantAmount

  return inv
}
