import PropTypes from 'prop-types';
// @mui
import { Link, Button, Divider, Typography, Stack } from '@mui/material';
import { DialogAnimate } from '../../../../components/animate';
// assets
import { OrderCompleteIllustration } from '../../../../assets/illustratuions';
import Iconify from "../../../../components/Iconify";
import { PATH_DASHBOARD } from "../../../../routes/path";

// ----------------------------------------------------------------------

CheckoutOrderComplete.propTypes = {
  open: PropTypes.bool,
  onReset: PropTypes.func,
  onDownloadPDF: PropTypes.func,
};

export default function CheckoutOrderComplete({ open, onReset }) {
  return (
    <DialogAnimate
      fullScreen
      open={open}
      PaperProps={{
        sx: {
          maxWidth: { md: 'calc(100% - 48px)' },
          maxHeight: { md: 'calc(100% - 48px)' },
        },
      }}>
      <Stack
        spacing={5}
        sx={{
          m: 'auto',
          maxWidth: 480,
          textAlign: 'center',
          px: { xs: 2, sm: 0 },
        }}>
        <Typography variant="h4">Hvala na kupovini!</Typography>

        <OrderCompleteIllustration sx={{ height: 260 }} />

        <Typography>
          Vaša porudžbina je uspešno kreirana.
          <br />
          <br />
          <br />
          <br />
          Kontaktiraće Vas prodavac oko detalja.
          <br /> Ukoliko imate dodatnih pitanja slobodno nas kontaktirajte na <Link href={PATH_DASHBOARD.chat.new}>Čet-u</Link> <br /> <br />
          Sve najbolje, IT-Creator
        </Typography>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack
          spacing={2}
          justifyContent="space-between"
          direction={{ xs: 'column-reverse', sm: 'row' }}>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={onReset}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}>
            Nastavi kupovinu
          </Button>
        </Stack>
      </Stack>
    </DialogAnimate>
  );
}
