import {useScroll} from 'framer-motion';
import {useEffect, useState} from 'react';

// ----------------------------------------------------------------------

export default function useOffSetTop(top = 100, options) {
  const { scrollY } = useScroll(options);

  const [value, setValue] = useState(false);

  useEffect(
    () =>
      // eslint-disable-next-line
      scrollY.onChange((scrollHeight) => {
        if (scrollHeight > top) {
          setValue(true);
        } else {
          setValue(false);
        }
      }),
    [scrollY, top]
  );

  return value;
}

// Usage
// const offset = useOffSetTop(100);

// Or
// const offset = useOffSetTop(100, {
//   container: ref,
// });
