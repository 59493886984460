import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { collection, doc, getDocs, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { ORDERS_STATUS } from "../../constants";
import { dispatch } from "../store";
import { reduceSaleProductQuantity } from "./products";

export const fetchAllUserOrders = createAsyncThunk("fetchAllUserOrders", async () => {
  let arr = [];
  const q = query(collection(DB, `bss/orders/saleProducts`), where("user", "==", AUTH.currentUser.uid));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return arr;
});

export const fetchAllOrders = createAsyncThunk("fetchAllOrders", async () => {
  let arr = [];
  const q = query(collection(DB, `bss/orders/saleProducts`));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return arr;
});

export const createNewOrder = createAsyncThunk("createNewOrder", async (data) => {
  const orderId = Date.now();
  const order = {
    ...data,
    user: AUTH.currentUser.uid,
    status: ORDERS_STATUS.created,
    time: Timestamp.fromMillis(orderId)
  };
  await setDoc(doc(DB, "bss", "orders", "saleProducts", orderId.toString()), order);
  await dispatch(reduceSaleProductQuantity(data?.items || []));
  return {
    ...order,
    id: orderId
  };
});

export const updateOrder = createAsyncThunk("updateOrder", async (data) => {
  const { orderId, status } = data;
  await updateDoc(doc(DB, "bss", "orders", "saleProducts", orderId.toString()), {
    status
  });
  return {
    id: orderId,
    status: status
  };
});

const initialState = {
  orders: [],
  userOrders: [],
  loading: false
};

export const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    //fetchAllUserOrders
    [fetchAllUserOrders.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllUserOrders.fulfilled]: (state, { payload }) => {
      state.userOrders = payload;
      state.loading = false;
    },
    [fetchAllUserOrders.pending]: (state) => {
      state.loading = false;
    },
    //fetchAllOrders
    [fetchAllOrders.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllOrders.fulfilled]: (state, { payload }) => {
      state.orders = payload;
      state.loading = false;
    },
    [fetchAllOrders.pending]: (state) => {
      state.loading = false;
    },
    //createNewOrder
    [createNewOrder.pending]: (state) => {
      state.loading = true;
    },
    [createNewOrder.fulfilled]: (state, { payload }) => {
      const userOrders = [...current(state.userOrders)];
      userOrders.push(payload);
      state.userOrders = userOrders;
      state.loading = false;
    },
    [createNewOrder.pending]: (state) => {
      state.loading = false;
    },
    //updateOrder
    [updateOrder.pending]: (state) => {
      state.loading = true;
    },
    [updateOrder.fulfilled]: (state, { payload }) => {
      const orders = [...current(state.orders)];
      const index = orders.findIndex(item => item.id === payload.id);
      if (index !== -1) {
        orders[index] = {
          ...orders[index],
          ...payload
        };
      }
      state.orders = orders;
      state.loading = false;
    },
    [updateOrder.pending]: (state) => {
      state.loading = false;
    }
  }
});
export default slice.reducer;


