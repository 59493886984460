import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Box, Stack } from "@mui/material";
//
import NavList from "./NavList";
import { useAuthContext } from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

NavSectionMini.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array
};

function NavSectionMini({ data, sx, ...other }) {
  const { role } = useAuthContext();
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx
      }}
      {...other}>
      {data.filter((item) => !item.roles || item.roles.includes(role)).map((group) =>
        <Items key={group.subheader} items={group.items} />
      )}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

Items.propTypes = {
  items: PropTypes.array
};

function Items({ items }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      <Box sx={{ width: 24, height: "1px", bgcolor: "divider", my: "8px !important" }} />
    </>
  );
}
