import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Stack } from "@mui/material";
// utils
import { hideScrollbarY } from "../../../utils/cssStyles";
//
import NavList from "./NavList";
import { useAuthContext } from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

NavSectionHorizontal.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array
};

function NavSectionHorizontal({ data, sx, ...other }) {
  const { role } = useAuthContext();
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mx: "auto",
        ...hideScrollbarY,
        ...sx
      }}
      {...other}
    >
      {data.filter((item) => !item.roles || item.roles.includes(role)).map((group) =>
        <Items key={group.subheader} items={group.items} />)}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

Items.propTypes = {
  items: PropTypes.array
};

function Items({ items }) {
  const { role } = useAuthContext();
  return (
    <>
      {items.filter((item) => !item.roles || item.roles.includes(role)).map((list) =>
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      )}
    </>
  );
}
