// routes
import {PATH_DASHBOARD} from "../../../routes/path";
// components
import SvgColor from "../../../components/svg-color";
import Iconify from "../../../components/Iconify";
import {BSS_ROLES} from "../../../constants";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;


// ----------------------------------------------------------------------

const ICONS = {
    discount: icon("ic_discount"),
    blog: icon("ic_blog"),
    cart: icon("ic_cart"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    dashboard: icon("ic_dashboard"),
    tenants: <Iconify icon="flat-color-icons:manager" width={1}/>,
};

const navConfig = [
        // ----------------------------------------------------------------------
        {
            subheader: "Početna",
            items: [
                {title: "Početna", path: PATH_DASHBOARD.general.app, icon: ICONS.analytics},
                {
                    title: "Klijenti", path: "#", icon: ICONS.user,
                    children: [
                        {title: "Lista korisnika", path: PATH_DASHBOARD.clients.root},
                        {title: "Dodavanje Esir korisnika", path: PATH_DASHBOARD.clients.newEsir},
                        {title: "Dodavanje eFakture korisnika", path: PATH_DASHBOARD.clients.newEfacture}
                    ]
                },
                {title: "Pomoć", path: PATH_DASHBOARD.help.root, icon: <Iconify icon="material-symbols:help"/>},
                {title: "Čet", path: PATH_DASHBOARD.chat.root, icon: ICONS.chat},
                {title: "Specifikacije", path: PATH_DASHBOARD.specification.root, icon: ICONS.banking},
                {title: "Prodavnica", path: PATH_DASHBOARD.shop.root, icon: ICONS.ecommerce},
            ]
        },
    {
        subheader: "Admin",
        roles: [BSS_ROLES.admin],
        items: [
            {title: "Distributeri", path: "#", icon: ICONS.tenants,
                children: [
                    {title: "Lista", path: PATH_DASHBOARD.admin.tenants.root},
                    {title: "Izveštaj", path: PATH_DASHBOARD.admin.tenants.report},
                ],
            },
            {title: "Uplate", path: PATH_DASHBOARD.admin.payments, icon: ICONS.invoice},
            {title: "Svi korisnici", path: PATH_DASHBOARD.admin.allUsers, icon: <Iconify icon="ph:users-four-fill"/>},
            {title: "Prodavnica", path: "#", icon: <Iconify icon="mdi:shop-settings"/>,
            children: [
                {title: "Lista proizvoda", path: PATH_DASHBOARD.admin.products.root},
                {title: "Dodavanje proizvoda", path: PATH_DASHBOARD.admin.products.new},
                {title: "Lista porudžbina", path: PATH_DASHBOARD.admin.orders},
            ]},
            {title: "Alati", path: "#", icon: <Iconify icon="ep:tools"/>,
                children: [
                    {title: "Dodavanje pravnog lica", path: PATH_DASHBOARD.admin.tools,
                        icon: <Iconify icon="mingcute:user-add-fill"/> },
                ]},
            {title: "Dužnici", path: PATH_DASHBOARD.admin.debtors.root, icon: <Iconify icon="flat-color-icons:debt"/>,
                children: [
                    {title: "Svi", path: PATH_DASHBOARD.admin.debtors.all},
                    {title: "Aktivni", path: PATH_DASHBOARD.admin.debtors.active},
                    {title: "Neaktivni", path: PATH_DASHBOARD.admin.debtors.inactive},
                ]},
        ]
    }
    ]
;

export default navConfig;
