import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dbRef } from "../../auth/FirebaseContext";
import { child, get } from "firebase/database";

const parseDate = (date) => {
  if (date) {
    const split = date.split("-");
    let month = Number(split[1]);
    if (month < 10) {
      month = `0${month}`;
    }
    if (split[2]) {
      let day = Number(split[2]);
      if (day < 10) {
        day = `0${day}`;
      }
      return `${split[0]}-${month}-${day}`;
    }
    return `${split[0]}-${month}`;
  }
};

export const fetchTenantSpecification = createAsyncThunk("fetchTenantSpecification", async (uid) => {
  const arr = [];
  const spec = (await get(child(dbRef, `/bss/tenantData/report/${uid}/sum`))).val();
  Object.keys(spec || {}).forEach((key, index) => {
    arr.push({
      id: `spec-${index}`,
      date: key,
      parsedDate: parseDate(key),
      value: spec[key]
    });
  });
  return arr;
});

export const fetchTenantSpecificationDetails = createAsyncThunk("fetchTenantSpecificationDetails",
  async ({ uid, date }) => {
    const arr = [];
    const spec = (await get(child(dbRef, `/bss/tenantData/report/${uid}/details/${date}`))).val();
    Object.keys(spec || {}).forEach((key, index) => {
      arr.push({
        id: `spec-d-${index}`,
        invRef: key,
        d: `${date}-${spec[key].d}`,
        parsedDate: parseDate(`${date}-${spec[key].d}`),
        a: spec[key].a,
        c: spec[key].c
      });
    });
    return arr;
  });

const initialState = {
  specifications: [],
  details: [],
  loading: false,
  loadingDetails: false
};

export const slice = createSlice({
  name: "specifications",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTenantSpecification.pending]: (state) => {
      state.loading = true;
    },
    [fetchTenantSpecification.fulfilled]: (state, { payload }) => {
      state.specifications = payload;
      state.loading = false;
    },
    [fetchTenantSpecification.rejected]: (state) => {
      state.loading = false;
    },
    [fetchTenantSpecificationDetails.pending]: (state) => {
      state.loadingDetails = true;
    },
    [fetchTenantSpecificationDetails.fulfilled]: (state, { payload }) => {
      state.details = payload;
      state.loadingDetails = false;
    },
    [fetchTenantSpecificationDetails.rejected]: (state) => {
      state.loadingDetails = false;
    }
  }
});

export default slice.reducer;
