import { collection, onSnapshot, query, where } from "firebase/firestore";
import { AUTH, DB, RDB } from "../../auth/FirebaseContext";
import { addMessage, addRoom, updateRoom } from "../../redux/slices/chat";
import { dispatch } from "../../redux/store";
import { onChildAdded, onChildChanged, onChildRemoved, ref } from "firebase/database";
import { addNotification, changeNotification, deleteNotification } from "../../redux/slices/notifications";

export function addMessageListener() {
  const q = query(collection(DB, `chat/messages/bss`),
    where("receiver", "==", AUTH.currentUser.uid));
  onSnapshot(q, (snapshot) => {
    for (const change of snapshot.docChanges()) {
      if (change.type === "added") {
        const doc = change.doc;
        dispatch(addMessage({
          ...doc.data(),
          id: doc.id
        }));
      }
    }
  });
  const q2 = query(collection(DB, `chat/rooms/bss`),
    where("participants", "array-contains", AUTH.currentUser.uid));
  onSnapshot(q2, (snapshot) => {
    for (const change of snapshot.docChanges()) {
      const doc = change.doc;
      if (change.type === "added") {
        dispatch(addRoom({
          ...doc.data(),
          id: doc.id
        }));
      }
      if (change.type === "modified") {
        dispatch(updateRoom({
          ...doc.data(),
          id: doc.id
        }));
      }
    }
  });
}

export function notificationsListener() {
  let messageRef = ref(RDB, `users/${AUTH.currentUser.uid}/private/messages/inbox`);
  onChildChanged(messageRef, (data) => {
    dispatch(changeNotification({
      ...data.val(),
      uid: data.key
    }));
  });
  onChildRemoved(messageRef, (data) => {
    dispatch(deleteNotification({
      ...data.val(),
      uid: data.key
    }));
  });
  onChildAdded(messageRef, (data) => {
    dispatch(addNotification({
      ...data.val(),
      uid: data.key
    }));
  });
}