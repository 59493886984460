import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Badge } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
import Iconify from "../../../components/Iconify";

const StyledRoot = styled("div")(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

CartWidget.propTypes = {
  totalItems: PropTypes.number
};

export default function CartWidget({ totalItems }) {
  return (
    <RouterLink to={PATH_DASHBOARD.shop.checkout}>
      <StyledRoot>
        <Badge showZero badgeContent={totalItems} color="error" max={99}>
          <Iconify icon="eva:shopping-cart-fill" width={24} />
        </Badge>
      </StyledRoot>
    </RouterLink>
  );
}
