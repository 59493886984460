import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
import { child, get, push, remove, update } from "firebase/database";
import { dbRef, storageRef } from "../../auth/FirebaseContext";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { Timestamp } from "firebase/firestore";

export const getAllProducts = createAsyncThunk("getAllProducts", async () => {
  let arr = [];
  (await get(child(dbRef, `public/saleProducts`))).forEach(child => {
    let childValue = child.val();
    arr.push({
      uid: child.key,
      ...childValue
    });
  });
  return arr;
});

export const insertNewSaleProduct = createAsyncThunk("insertNewSaleProduct", async (data) => {
  return (await push(child(dbRef, `public/saleProducts`), {
    name: data.name,
    price: data.price,
    description: data.description,
    discount: data.discount !== undefined ? Number(data.discount) : 0,
    lager: data.lager !== undefined ? Number(data.lager) : 0,
    createdAt: Timestamp.fromMillis(Date.now()),
    images: [],
    active: true
  }));
});
export const updateSaleProduct = createAsyncThunk("updateSaleProduct", async (data) => {
  (await update(child(dbRef, `public/saleProducts/${data.uid}`), {
    name: data.name,
    price: data.price,
    description: data.description,
    discount: data.discount !== undefined ? Number(data.discount) : 0,
    lager: data.lager !== undefined ? Number(data.lager) : 0,
    images: [],
    active: data.active
  }));
});

export const removeSaleProduct = createAsyncThunk("removeSaleProduct", async (data) => {
  await remove(child(dbRef, `public/saleProducts/${data.uid}`));
});

export const uploadSaleProductImages = createAsyncThunk("uploadSaleProductImages", async (data) => {
  const { images, uid } = data;
  const val = (await get(child(dbRef, `public/saleProducts/${uid}`))).val();
  const oldImages = val.images || [];
  let i = oldImages.length;
  const imagesPath = [];
  for (const image of images) {
    let imageRef = ref(ref(storageRef, "public/saleProductsImages"), `${uid}_${i}.png`);
    await uploadBytes(imageRef, image);
    imagesPath.push(`${uid}_${i}.png`);
    i = i + 1;
  }

  const newImages = [...imagesPath, ...oldImages];
  (await update(child(dbRef, `public/saleProducts/${uid}`), {
    images: newImages
  }));
  return {
    images: newImages,
    uid
  };
});

export const removeSaleProductImage = createAsyncThunk("removeSaleProductImage", async (data) => {
  const { image, uid } = data;
  const desertRef = ref(storageRef, `public/saleProductsImages/${image}`);
  await deleteObject(desertRef);
  const val = (await get(child(dbRef, `public/saleProducts/${uid}`))).val();
  const images = val.images || [];
  const index = images.indexOf(image);
  images.splice(index, 1);
  (await update(child(dbRef, `public/saleProducts/${uid}`), {
    images: images
  }));
});

export const reduceSaleProductQuantity = createAsyncThunk("reduceSaleProductQuantity", async (data)=>{
  const response = []
  for (const item of data) {
    const product = (await get(child(dbRef, `public/saleProducts/${item.uid}`))).val();
    const newQuantity = Number(product.lager) - Number(item.quantity);
    (await update(child(dbRef, `public/saleProducts/${item.uid}`),{
      lager: Number(newQuantity)
    }))
    response.push({
      uid: item.uid,
      lager: Number(newQuantity)
    })
  }
  return response;
})

const initialState = {
  products: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0
  },
  loading: false,
  updating: false
};

export const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;
      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      state.checkout.cart = cart;
      state.checkout.discount = state.checkout.discount || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - state.checkout.discount;
      state.checkout.totalItems = totalItems;
    },
    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;
      if (isEmptyCart) {
        if(newProduct.lager > 0){
          state.checkout.cart = [...state.checkout.cart, newProduct];
        }
      } else {
        state.checkout.cart = current(state.checkout.cart).map((product) => {
          const isExisted = product.uid === newProduct.uid;

          if (isExisted) {
            if (product.lager > 0 && product.lager > product.quantity) {
              return {
                ...product,
                quantity: product.quantity + 1
              };
            }
          }

          return product;
        });
      }
      if(newProduct.lager > 0){
        state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], "uid");
      }
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
    },
    deleteCart(state, action) {
      state.checkout.cart = state.checkout.cart.filter((product) => product.uid !== action.payload);
    },
    resetCart(state) {
      state.checkout.cart = [];
      state.checkout.billing = null;
      state.checkout.activeStep = 0;
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.totalItems = 0;
    },
    increaseQuantity(state, action) {
      const productId = action.payload;
      state.checkout.cart = state.checkout.cart.map((product) => {
        if (product.uid === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });
    },
    decreaseQuantity(state, action) {
      const productId = action.payload;
      state.checkout.cart = state.checkout.cart.map((product) => {
        if (product.uid === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });
    },
    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      state.checkout.activeStep = action.payload;
    },
    createBilling(state, action) {
      state.checkout.billing = action.payload;
    }
  },
  extraReducers: {
    //getAllProducts
    [getAllProducts.pending]: (state) => {
      state.loading = true;
    },
    [getAllProducts.fulfilled]: (state, { payload }) => {
      state.products = payload;
      state.loading = false;
    },
    [getAllProducts.rejected]: (state) => {
      state.loading = false;
    },
    //insertNewSaleProduct
    [insertNewSaleProduct.pending]: (state) => {
      state.updating = true;
    },
    [insertNewSaleProduct.fulfilled]: (state, { meta, payload }) => {
      const products = [...current(state.products)];
      products.push({
        ...meta.arg,
        createdAt: Timestamp.fromDate(new Date()),
        uid: payload.key
      });
      state.products = products;
      state.updating = false;
    },
    [insertNewSaleProduct.rejected]: (state) => {
      state.updating = false;
    },
    //updateSaleProduct
    [updateSaleProduct.pending]: (state) => {
      state.updating = true;
    },
    [updateSaleProduct.fulfilled]: (state, { meta }) => {
      const products = [...current(state.products)];
      const index = products.findIndex(pr => pr.uid === meta.arg.uid);
      if (index !== -1) {
        products[index] = meta.arg;
        state.products = products;
      }
      state.updating = false;
    },
    [updateSaleProduct.rejected]: (state) => {
      state.updating = false;
    },
    //removeSaleProduct
    [removeSaleProduct.pending]: (state) => {
      state.updating = true;
    },
    [removeSaleProduct.fulfilled]: (state, { meta }) => {
      const products = [...current(state.products)];
      const index = products.findIndex(pr => pr.uid === meta.arg.uid);
      products.splice(index, 1);
      state.products = products;
      state.updating = false;
    },
    [removeSaleProduct.rejected]: (state) => {
      state.updating = false;
    },
    //uploadSaleProductImages
    [uploadSaleProductImages.pending]: (state) => {
      state.updating = true;
    },
    [uploadSaleProductImages.fulfilled]: (state, { payload }) => {
      const products = [...current(state.products)];
      const index = products.findIndex(pr => pr.uid === payload.uid);
      if (index !== -1) {
        products[index] = {
          ...products[index],
          images: payload.images
        };
        state.products = products;
      }
      state.updating = false;
    },
    [uploadSaleProductImages.rejected]: (state) => {
      state.updating = false;
    },
    //removeSaleProductImage
    [removeSaleProductImage.pending]: (state) => {
      state.updating = true;
    },
    [removeSaleProductImage.fulfilled]: (state, { meta }) => {
      const products = [...current(state.products)];
      const index = products.findIndex(pr => pr.uid === meta.arg.uid);
      if (index !== -1) {
        const images = [...products[index].images];
        const imgIndex = images.indexOf(meta.arg.image);
        images.splice(imgIndex, 1);
        products[index] = {
          ...products[index],
          images: images
        };
        state.products = products;
      }
      state.updating = false;
    },
    [removeSaleProductImage.rejected]: (state) => {
      state.updating = false;
    },
    [reduceSaleProductQuantity.fulfilled]: (state, {payload}) => {
      const products = [...current(state.products)];
      for (const item of payload) {
        const index = products.findIndex(pr => pr.uid === item.uid);
        if(index !== -1){
          products[index] = {
            ...products[index],
            lager: item.lager
          }
        }
      }
      state.products = products
    }
  }
});
export const {
  addToCart, deleteCart, resetCart, decreaseQuantity, getCart, increaseQuantity, backStep,
  gotoStep, nextStep, createBilling
} = slice.actions;
export default slice.reducer;


