import {useState} from "react";
import {useNavigate} from "react-router-dom";
// @mui
import {alpha} from "@mui/material/styles";
import {Box, Divider, MenuItem, Stack, Typography} from "@mui/material";
// routes
import {PATH_AUTH, PATH_DASHBOARD} from "../../../routes/path";
// auth
import {useAuthContext} from "../../../auth/useAuthContext";
// components
import {CustomAvatar} from "../../../components/custom-avatar";
import {useSnackbar} from "notistack";
import MenuPopover from "../../../components/menu-popover";
import {IconButtonAnimate} from "../../../components/animate";

// ----------------------------------------------------------------------

const OPTIONS = [
    {
        label: "Početna",
        linkTo: "/"
    },
    {
        label: "Podešavanja",
        linkTo: PATH_DASHBOARD.settings.root
    },
    {
        label: "Moje porudžbine",
        linkTo: PATH_DASHBOARD.userOrders.root
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();

    const {logout, user} = useAuthContext();

    const {enqueueSnackbar} = useSnackbar();

    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogout = async () => {
        try {
            logout();
            navigate(PATH_AUTH.login, {replace: true});
            handleClosePopover();
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Došlo je do greške!", {variant: "error"});
        }
    };

    const handleClickItem = (path) => {
        handleClosePopover();
        navigate(path);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpenPopover}
                sx={{
                    p: 0,
                    ...(openPopover && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
                        }
                    })
                }}>
                <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName}/>
            </IconButtonAnimate>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 200, p: 0}}>
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.displayName}
                    </Typography>

                    <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
                        {user?.role}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: "dashed"}}/>

                <Stack sx={{p: 1}}>
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: "dashed"}}/>

                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    Odjavi se
                </MenuItem>
            </MenuPopover>
        </>
    );
}