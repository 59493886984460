import PropTypes from "prop-types";
// @mui
import { Box, Button, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
// utils
import { fCurrency } from "../../../../utils/formatNumber";
import Iconify from "../../../../components/Iconify";
// components


// ----------------------------------------------------------------------

CheckoutSummary.propTypes = {
  onEdit: PropTypes.func,
  total: PropTypes.number,
  discount: PropTypes.number,
  subtotal: PropTypes.number,
  shipping: PropTypes.number,
  enableEdit: PropTypes.bool,
  enableDiscount: PropTypes.bool,
  onApplyDiscount: PropTypes.func
};

export default function CheckoutSummary({
                                          total,
                                          onEdit,
                                          subtotal,
                                          enableEdit = false
                                        }) {

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title="Sumiranje"
        action={
          enableEdit && (
            <Button size="small" onClick={onEdit} startIcon={<Iconify icon="eva:edit-fill" />}>
              Izmeni
            </Button>
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Međuzbir
            </Typography>
            <Typography variant="subtitle2">{fCurrency(`${subtotal}`)}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">Ukupno</Typography>
            <Box sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1" sx={{ color: "error.main" }}>
                {fCurrency(`${total}`)}
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                (Porez uključen u cenu)
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
