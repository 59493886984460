import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import { Card, Container, Table, TableBody, TableContainer } from "@mui/material";
// redux
import { dispatch, useSelector } from "../../../redux/store";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// components
import { useSettingsContext } from "../../../components/settings";
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable
} from "../../../components/table";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
// sections
import Scrollbar from "../../../components/Scrollbar";
import { useSnackbar } from "notistack";
import { fetchAllOrders, updateOrder } from "../../../redux/slices/orders";
import OrdersTableToolbar from "./OrdersTableToolbar";
import OrdersTableRow from "./OrdersTableRow";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "items", label: "" },
  { id: "fullAddress", label: "Adresa" },
  { id: "discount", label: "Popust" },
  { id: "name", label: "Ime" },
  { id: "lastname", label: "Prezime" },
  { id: "phoneNumber", label: "Telefon" },
  { id: "time", label: "Datum porudžbine" },
  { id: "status", label: "Status" },
  { id: "totalItems", label: "Ukupno proizvoda", align: "right" },
  { id: "total", label: "Ukupno", align: "right" },
  { id: "", label: "Akcije", align: "right" }
];
// ----------------------------------------------------------------------

export default function OrdersPage() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultOrderBy: "createdAt"
  });

  const { themeStretch } = useSettingsContext();
  const { orders, loading } = useSelector((state) => state.orders);

  const [filterName, setFilterName] = useState("");

  useEffect(() => {
    dispatch(fetchAllOrders());
  }, []);

  const dataFiltered = applyFilter({
    inputData: orders,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== "";

  const isNotFound = (!dataFiltered.length && !!filterName) || (!loading && !dataFiltered.length);
  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
  };

  const handleEditRow = (status, orderId) => {
    dispatch(updateOrder({
      orderId,
      status
    })).then(_ => {
      enqueueSnackbar("Uspešno ste promenili status", { variant: "success" });
    }).catch(reason => {
      console.error("reason", reason);
      enqueueSnackbar("Greška prilikom izmene statusa", { variant: "error" });
    });
  };

  return (
    <>
      <Helmet>
        <title> BSS - Lista porudžbina</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Lista porudžbina"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "Lista porudžbina" }
          ]}
        />

        <Card>
          <OrdersTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            isFiltered={isFiltered}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orders.length}
                  onSort={onSort}
                />

                <TableBody>
                  {(loading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <OrdersTableRow
                          key={`OrdersTableRow-${row.uid}-${index}`}
                          row={row}
                          onEditRow={handleEditRow}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, orders.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (product) => product.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}