import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { Button, Card, Container, Table, TableBody, TableContainer } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getAllProducts, removeSaleProduct } from "../../../redux/slices/products";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// components
import { useSettingsContext } from "../../../components/settings";
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable
} from "../../../components/table";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
// sections
import Iconify from "../../../components/Iconify";
import ProductTableToolbar from "./ProductTableToolbar";
import ProductTableRow from "./ProductTableRow";
import Scrollbar from "../../../components/Scrollbar";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Proizvod", align: "left" },
  { id: "createdAt", label: "Vreme kreiranja", align: "left" },
  { id: "active", label: "Aktivan", align: "center" },
  { id: "lager", label: "Na lageru", align: "center" },
  { id: "price", label: "Cena", align: "right" },
  { id: "discount", label: "Popust", align: "right" },
  { id: "", label: "Akcije", align: "right" }
];
// ----------------------------------------------------------------------

export default function ProductManagementPage() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultOrderBy: "createdAt"
  });

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { products, loading } = useSelector((state) => state.products);

  const [filterName, setFilterName] = useState("");

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const dataFiltered = applyFilter({
    inputData: products,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== "";

  const isNotFound = (!dataFiltered.length && !!filterName) || (!loading && !dataFiltered.length);
  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = (row, onSuccess)=> {
    dispatch(removeSaleProduct(row)).unwrap().then(_ => {
      setPage(0);
      if (onSuccess) {
        onSuccess();
      }
    }).catch(reason => {
      console.error("removeSaleProduct", reason);
      enqueueSnackbar("Greška prilikom brisanja proizvoda", { variant: "error" });
    });
  };

  const handleEditRow = (row) => {
    navigate(PATH_DASHBOARD.admin.products.edit, {
      state: row
    });
  };

  const handleResetFilter = () => {
    setFilterName("");
  };

  return (
    <>
      <Helmet>
        <title> BSS - Lista proizvoda</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Lista proizvoda"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "Lista proizvoda" }
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.admin.products.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Nov proizvod
            </Button>
          }
        />

        <Card>
          <ProductTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            isFiltered={isFiltered}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={products.length}
                  onSort={onSort}
                />

                <TableBody>
                  {(loading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <ProductTableRow
                          key={`product-${row.uid}-${index}`}
                          row={row}
                          onDeleteRow={handleDeleteRow}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, products.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (product) => product.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}