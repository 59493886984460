import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import orderBy from "lodash/orderBy";
// form
import { useForm } from "react-hook-form";
// @mui
import { Container, Stack } from "@mui/material";
// redux
import { dispatch, useSelector } from "../../redux/store";
// routes
import { PATH_DASHBOARD } from "../../routes/path";
// components
import FormProvider from "../../components/hook-form";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
// sections
import { ShopProductList, ShopProductSearch, ShopProductSort } from "./components";
import CartWidget from "./components/CartWidget";
import { getAllProducts } from "../../redux/slices/products";
import EmptyContent from "../../components/empty-content";

// ----------------------------------------------------------------------

export default function ShopPage() {
  const { themeStretch } = useSettingsContext();

  const { products, checkout, loading } = useSelector((state) => state.products);

  const defaultValues = {
    gender: [],
    category: "All",
    colors: [],
    priceRange: [0, 200],
    rating: "",
    sortBy: "featured"
  };

  const methods = useForm({
    defaultValues
  });

  const {
    watch
  } = methods;

  const values = watch();

  const dataFiltered = applyFilter(products.filter(pr => pr.active), values);

  useEffect(() => {
    if (products.length === 0)
      dispatch(getAllProducts());
  }, [products.length]);

  return (
    <>
      <Helmet>
        <title>BSS - Prodavnica</title>
      </Helmet>

      <FormProvider methods={methods}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <CustomBreadcrumbs
            heading="Prodavnica"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              {
                name: "Prodavnica",
                href: PATH_DASHBOARD.shop.root
              }
            ]}
          />

          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ sm: "center" }}
            justifyContent="space-between"
            sx={{ mb: 2 }}>
            <ShopProductSearch />

            <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>

              <ShopProductSort />
            </Stack>
          </Stack>
          <ShopProductList products={dataFiltered} loading={loading} />
          {dataFiltered.length === 0 && !loading &&
            <EmptyContent title="Nema rezultata" description="Trenutno nema dodatih kasa" />
          }

          <CartWidget totalItems={checkout.totalItems} />
        </Container>
      </FormProvider>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter(products, filters) {
  const { sortBy } = filters;

  // SORT BY
  if (sortBy === "featured") {
    products = orderBy(products, ["name"], ["desc"]);
  }

  if (sortBy === "newest") {
    products = orderBy(products, ["createdAt"], ["desc"]);
  }

  if (sortBy === "priceDesc") {
    products = orderBy(products, ["price"], ["desc"]);
  }

  if (sortBy === "priceAsc") {
    products = orderBy(products, ["price"], ["asc"]);
  }

  return products;
}