import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
// utils
import { fDate } from "../../../utils/formatTime";
import { fCurrency } from "../../../utils/formatNumber";
// components
import MenuPopover from "../../../components/menu-popover";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/label";
import { ORDERS_STATUS } from "../../../constants";

// ----------------------------------------------------------------------

OrdersTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func
};

export default function OrdersTableRow({
                                         row,
                                         onEditRow
                                       }) {
  const { id, items, fullAddress, discount, name, lastname, phoneNumber, status, time, total, totalItems } = row;

  const [open, setOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton
            size="small"
            color={open ? "inherit" : "default"}
            onClick={() => setOpen(!open)}>
            <Iconify icon={open ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"} />
          </IconButton>
        </TableCell>
        <TableCell>
          {fullAddress}
        </TableCell>
        <TableCell>
          {discount} %
        </TableCell>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {lastname}
        </TableCell>
        <TableCell>
          {phoneNumber}
        </TableCell>
        <TableCell>{fDate(new Date(time.toDate()))}</TableCell>

        <TableCell align="center">
          <Label color={getStatusColor(status)}>
            {status}
          </Label>
        </TableCell>
        <TableCell align="right">{totalItems}</TableCell>
        <TableCell align="right">{fCurrency(`${total}`)}</TableCell>

        <TableCell align="right">
          {(status !== ORDERS_STATUS.rejected || status !== ORDERS_STATUS.finished) &&
            <IconButton color={openPopover ? "primary" : "default"} onClick={handleOpenPopover}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={6}>
          <Collapse in={open} unmountOnExit>
            <Paper
              variant="outlined"
              sx={{
                py: 2,
                borderRadius: 1.5,
                ...(open && {
                  boxShadow: (theme) => theme.customShadows.z20
                })
              }}>
              <Typography variant="h6" sx={{ m: 2, mt: 0 }}>
                Proizvodi
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Naziv</TableCell>
                    <TableCell>Cena</TableCell>
                    <TableCell>Količina</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map((item, index) => (
                    <TableRow key={`ordered-item-${item.uid}-${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell>{fCurrency(`${item.price}`)}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}>

        <MenuItem
          onClick={() => {
            onEditRow(ORDERS_STATUS.accepted, id);
            handleClosePopover();
          }}>
          <Iconify icon="healthicons:i-documents-accepted" />
          Prihvati
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditRow(ORDERS_STATUS.rejected, id);
            handleClosePopover();
          }}>
          <Iconify icon="fluent:text-change-reject-20-filled" />
          Otkaži
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditRow(ORDERS_STATUS.finished, id);
            handleClosePopover();
          }}>
          <Iconify icon="gis:finish" />
          Završi
        </MenuItem>
      </MenuPopover>
    </>
  );
}

const getStatusColor = (status) => {
  switch (status) {
    case ORDERS_STATUS.accepted:
      return "success";
    case ORDERS_STATUS.rejected:
      return "error";
    case ORDERS_STATUS.finished:
      return "primary";
    default:
      return "info";
  }
};