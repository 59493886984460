import PropTypes from "prop-types";
import { useEffect } from "react";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
// @mui
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/path";
// utils
import { fCurrency } from "../../../../utils/formatNumber";
// components
import Label from "../../../../components/label";
import { IncrementerButton } from "../../../../components/custom-input";
import FormProvider from "../../../../components/hook-form";
import Iconify from "../../../../components/Iconify";

// ----------------------------------------------------------------------

ProductDetailsSummary.propTypes = {
  cart: PropTypes.array,
  onAddCart: PropTypes.func,
  product: PropTypes.object,
  onGotoStep: PropTypes.func
};

export default function ProductDetailsSummary({ cart, product, onAddCart, onGotoStep, ...other }) {
  const navigate = useNavigate();

  const {
    uid,
    name,
    price,
    discount,
    lager
  } = product;

  const alreadyProduct = cart.map((item) => item.uid).includes(uid);

  const isMaxQuantity = lager === 0 ? true :
    cart.filter((item) => item.uid === uid).map((item) => item.quantity)[0] >= lager;

  const defaultValues = {
    ...product,
    quantity: lager < 1 ? 0 : 1
  };

  const methods = useForm({
    defaultValues
  });

  const { reset, watch, setValue, handleSubmit } = methods;

  const values = watch();

  useEffect(() => {
    if (product) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onSubmit = async (data) => {
    try {
      if (!alreadyProduct) {
        onAddCart({
          ...data,
          colors: [values.colors],
          subtotal: data.price * data.quantity
        });
      }
      onGotoStep(0);
      navigate(PATH_DASHBOARD.shop.checkout);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCart = async () => {
    try {
      onAddCart({
        ...values,
        subtotal: values.price * values.quantity
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={3}
        sx={{
          p: (theme) => ({
            md: theme.spacing(5, 5, 0, 2)
          })
        }}
        {...other}>
        <Stack spacing={2}>
          <Label
            variant="soft"
            color={lager > 0 ? "success" : "error"}
            sx={{ textTransform: "uppercase", mr: "auto" }}>
            {sentenceCase(lager > 0 ? `Na stanju: ${lager}` : "Nema na stanju")}
          </Label>

          <Typography
            variant="overline"
            component="div"
            sx={{
              color: discount > 0 ? "error.main" : "info.main"
            }}>
            {discount}
          </Typography>

          <Typography variant="h5">{name}</Typography>

          <Typography variant="h4">
            {discount > 0 && (
              <Box
                component="span"
                sx={{ color: "text.disabled", textDecoration: "line-through", mr: 0.5 }}>
                {fCurrency(`${price}`)} RSD
              </Box>
            )}

            {discount > 0 ?
              fCurrency(`${price - (price * discount / 100)}`)
              :
              fCurrency(`${price}`)
            }
            {` RSD`}
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" sx={{ height: 36, lineHeight: "36px" }}>
            Količina
          </Typography>

          <Stack spacing={1}>
            <IncrementerButton
              name="quantity"
              quantity={values.quantity}
              disabledDecrease={values.quantity <= 1}
              disabledIncrease={values.quantity >= lager}
              onIncrease={() => setValue("quantity", values.quantity + 1)}
              onDecrease={() => setValue("quantity", values.quantity - 1)}
            />

            <Typography
              variant="caption"
              component="div"
              sx={{ textAlign: "right", color: "text.secondary" }}>
              Dostupno: {lager}
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            disabled={isMaxQuantity}
            size="large"
            color="warning"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add-shopping-cart" />}
            onClick={handleAddCart}
            sx={{ whiteSpace: "nowrap" }}>
            Dodaj u korpu
          </Button>

          <Button disabled={isMaxQuantity} fullWidth size="large" type="submit" variant="contained">
            Poruči odmah
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          {[{ name: "facebook", icon: "" }].map((social) => (
            <IconButton key={social.name}>
              <Iconify icon={social.icon} />
            </IconButton>
          ))}
        </Stack>
      </Stack>
    </FormProvider>
  );
}
