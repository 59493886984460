import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AUTH, storageRef } from "../../auth/FirebaseContext";
import { sendPasswordResetEmail, updateProfile} from "firebase/auth";

export const fetchBssUserLogo = createAsyncThunk("fetchBssUserLogo", async () => {
  const imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await getDownloadURL(imageRef);
});

export const insertBssUserLogo = createAsyncThunk("insertBssUserLogo", async (image) => {
  let imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await uploadBytes(imageRef, image);
});

export const requestPasswordResetForBssUser = createAsyncThunk("requestPasswordResetForBssUser", async () => {
  return await sendPasswordResetEmail(AUTH, AUTH.currentUser.email);
});

export const updateBssUserProfile = createAsyncThunk("updateBssUserProfile", async (user) => {
  let currentUser = await AUTH.currentUser;
  if (currentUser.displayName !== user.displayName) {
    await updateProfile(currentUser, {
      displayName: user.displayName
    });
  }
})

const initialState = {
  image: undefined,
  loading: false,
  uploading: false
};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBssUserLogo.pending]: (state) => {
      state.loading = true;
    },
    [fetchBssUserLogo.fulfilled]: (state, { payload }) => {
      state.image = payload;
      state.loading = false;
    },
    [fetchBssUserLogo.rejected]: (state) => {
      state.loading = false;
    },
    [insertBssUserLogo.pending]: (state) => {
      state.uploading = true;
    },
    [insertBssUserLogo.fulfilled]: (state, { payload }) => {
      state.image = payload;
      state.uploading = false;
    },
    [insertBssUserLogo.rejected]: (state) => {
      state.uploading = false;
    },
    [requestPasswordResetForBssUser.pending]: (state) => {
      state.loading = true;
    },
    [requestPasswordResetForBssUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [requestPasswordResetForBssUser.rejected]: (state) => {
      state.loading = false;
    },
    [updateBssUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateBssUserProfile.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBssUserProfile.rejected]: (state) => {
      state.loading = false;
    }
  }
});
export const { addMessage, changeMessage, deleteMessage } = slice.actions;
export default slice.reducer;


