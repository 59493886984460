import { useEffect, useState } from "react";
import { paramCase } from "change-case";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useNavigate } from "react-router-dom";
// @mui
import { Autocomplete, InputAdornment, Link, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// components
import { CustomTextField } from "../../../components/custom-input";
import SearchNotFound from "../../../components/search-not-found";
import LazyImage from "../../../components/image/LazyImage";
import { dispatch, useSelector } from "../../../redux/store";
import { getAllProducts } from "../../../redux/slices/products";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

export default function ShopProductSearch() {
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.products);

  const [searchProducts, setSearchProducts] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts()).unwrap().then(value => {
        setSearchResults(value);
      });
    } else {
      setSearchResults(products);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length]);

  const handleChangeSearch = async (value) => {
    setSearchProducts(value);
  };

  const handleGotoProduct = (product) => {
    navigate(PATH_DASHBOARD.shop.details(paramCase(product.name)), {
      state: product
    });
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleGotoProduct(searchProducts);
    }
  };

  return (
    <Autocomplete
      size="small"
      autoHighlight
      popupIcon={null}
      options={searchResults}
      onInputChange={(event, value) => handleChangeSearch(value)}
      getOptionLabel={(product) => product.name}
      noOptionsText={<SearchNotFound query={searchProducts} />}
      isOptionEqualToValue={(option, value) => option.uid === value.uid}
      componentsProps={{
        popper: {
          sx: {
            width: `280px !important`
          }
        },
        paper: {
          sx: {
            "& .MuiAutocomplete-option": {
              px: `8px !important`
            }
          }
        }
      }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          width={220}
          placeholder="Pretraga..."
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: "text.disabled" }} />
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, product, { inputValue }) => {
        const { name, images } = product;
        const matches = match(name, inputValue);
        const parts = parse(name, matches);

        return (
          <li {...props} onClick={() => handleGotoProduct(product)}>
            <LazyImage
              url={images === undefined ? undefined : images[0]}
              sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
            />

            <Link underline="none">
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  variant="subtitle2"
                  color={part.highlight ? "primary" : "textPrimary"}>
                  {part.text}
                </Typography>
              ))}
            </Link>
          </li>
        );
      }}
    />
  );
}
