import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { Container } from "@mui/material";
// redux
// routes
import { PATH_DASHBOARD } from "../../../../routes/path";
// components
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../../components/settings";
import ProductNewEditForm from "./ProductNewEditForm";
import { useEffect, useState } from "react";
import { getProductImage } from "../../../../helper/products";
import LoadingScreen from "../../../../components/loading-screen";
// sections

// ----------------------------------------------------------------------

export default function ProductEditPage() {
  const { themeStretch } = useSettingsContext();
  const location = useLocation();
  const navigate = useNavigate();
  let stateProduct = location.state;
  const [loading, setLoading] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(stateProduct)

  useEffect(() => {
    if (!currentProduct) {
      navigate(PATH_DASHBOARD.admin.products.root);
    }else{
      fetchImages().then(response=>{
        if(response){
          setCurrentProduct({
            ...currentProduct,
            images: response
          })
        }
      })
    }
  }, [currentProduct, navigate]);

  const fetchImages = async () => {
    try{
      setLoading(true)
      const images = currentProduct?.images || [];
      if(images.length === 0){
        setLoading(false)
        return undefined
      }
      const newImages = [];
      for (const image of images) {
        const url = await getProductImage(image)
        newImages.push(url)
      }
      setLoading(false)
      return newImages;
    }catch (e){
      setLoading(false);
    }
  }

  if(loading)
    return <LoadingScreen/>

  return (
    <>
      <Helmet>
        <title> BSS - izmena proizvoda</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Izmena proizvoda"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Lista proizvoda",
              href: PATH_DASHBOARD.admin.products.root
            },
            { name: currentProduct?.name }
          ]}
        />

        <ProductNewEditForm isEdit currentProduct={currentProduct} />
      </Container>
    </>
  );
}
