import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {extendLicense, findClient, updateBusinessApi} from "../../api";
import {ref, uploadBytes} from "firebase/storage";
import {dbRef, storageRef} from "../../auth/FirebaseContext";
import {child, update} from "firebase/database";

export const fetchAllAdminClients = createAsyncThunk("fetchAllAdminClients", async (data) => {
    return (await findClient(data)).data;
});

export const fetchAllDebtors = createAsyncThunk("fetchAllDebtors", async (type) => {
    return (await findClient({
        debtors: type
    })).data;
});

export const extendClientLicense = createAsyncThunk("extendClientLicense", async (data) => {
    return await extendLicense(data);
});

export const updateBusinessData = createAsyncThunk("updateBusinessData", async (data) => {
    return await updateBusinessApi(data);
});

// direktno nad fb
export const updateOrAddBusinessData = createAsyncThunk("updateOrAddBusinessData", async (data) => {
    const {pib, ...otherData} = data;
    await update(child(dbRef, `/public/businesses/${pib}`), otherData);
})


export const updateUserImage = createAsyncThunk("updateUserImage", async (data) => {
    const {image, userUid} = data;
    let imageRef = ref(ref(storageRef, "public/userphotos"), `${userUid}.png`);
    return await uploadBytes(imageRef, image);
});

const initialState = {
    allUsers: [],
    allDebtors: [],
    loading: false,
    updating: false
};

export const slice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        resetFoundedResult: state => {
            state.allUsers = []
        }
    },
    extraReducers: {
        //fetchAllAdminClients
        [fetchAllAdminClients.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllAdminClients.fulfilled]: (state, {payload}) => {
            state.allUsers = payload.map(item => ({
                ...item,
                contractID: item.userFinance?.contractID,
                balance: item.userFinance?.balance,
                lastSignInTime: item.metadata.lastSignInTime,
                creationTime: item.metadata.creationTime,
            }));
            state.loading = false;
        },
        [fetchAllAdminClients.rejected]: (state) => {
            state.loading = false;
        },
        //fetchAllDebtors
        [fetchAllDebtors.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllDebtors.fulfilled]: (state, {payload}) => {
            state.allDebtors = payload;
            state.loading = false;
        },
        [fetchAllDebtors.rejected]: (state) => {
            state.loading = false;
        },
        //extendClientLicense
        [extendClientLicense.pending]: (state) => {
            state.updating = true;
        },
        [extendClientLicense.fulfilled]: (state) => {
            state.updating = false;
        },
        [extendClientLicense.rejected]: (state) => {
            state.updating = false;
        },
        //updateBusinessData
        [updateBusinessData.pending]: (state) => {
            state.updating = true;
        },
        [updateBusinessData.fulfilled]: (state) => {
            state.updating = false;
        },
        [updateBusinessData.rejected]: (state) => {
            state.updating = false;
        },
        //updateUserImage
        [updateUserImage.pending]: (state) => {
            state.updating = true;
        },
        [updateUserImage.fulfilled]: (state) => {
            state.updating = false;
        },
        [updateUserImage.rejected]: (state) => {
            state.updating = false;
        }
    }
});
export const {resetFoundedResult} = slice.actions
export default slice.reducer;


