import { useLayoutEffect, useState } from "react";
import { getProductImage } from "../../helper/products";
import Image from "./Image";
import { Skeleton } from "@mui/material";

export default function LazyImage({ url, ratio, sx, ...other}) {
  const [image, setImage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    let isMounted = true;
    if (url !== undefined) {
      if (isMounted) {
        setLoading(true);
        getProductImage(url).then(value => {
          setImage(value);
          setLoading(false);
        }).catch(reason => {
          console.error("getProductImage", reason);
          setLoading(false);
        });
      }
    }

    return () => isMounted = false;
  }, [url]);

  if (loading) {
    return (<Skeleton variant="circular" width={40} height={40} />);
  }

  return (
    <Image
      disabledEffect
      visibleByDefault
      alt={image}
      src={image}
      ratio={ratio}
      sx={sx}
      {...other}
    />
  );
}