import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dbRef } from "../../auth/FirebaseContext";
import { child, get } from "firebase/database";
import { fetchTenantActivationsApi, fetchTenantsFinancialReportApi } from "../../api";

export const fetchAllTenants = createAsyncThunk("fetchAllTenants", async () => {
  const arr = [];
  const tenantSnap = (await get(child(dbRef, `/bss/tenants`)));
  tenantSnap.forEach(snap => {
    const data = snap.val();
    arr.push({
      uid: snap.key,
      contractPrefix: data.protected.contractPrefix,
      groupID: data.protected.groupID
    });
  });
  return arr;
});

export const fetchTenantsFinancialReport = createAsyncThunk("fetchTenantsFinancialReport", async (data) => {
  return await fetchTenantsFinancialReportApi(data)
});

export const fetchTenantActivations = createAsyncThunk("fetchTenantActivations", async (data) => {
  return await fetchTenantActivationsApi(data)
});

const initialState = {
  tenants: [],
  tenantData: [],
  tenantActivations: [],
  loading: false
};

export const slice = createSlice({
  name: "tenants",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllTenants.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllTenants.fulfilled]: (state, { payload }) => {
      state.tenants = payload;
      state.loading = false;
    },
    [fetchAllTenants.rejected]: (state) => {
      state.loading = false;
    },
    // fetchTenantsFinancialReport
    [fetchTenantsFinancialReport.pending]: (state) => {
      state.loading = true;
    },
    [fetchTenantsFinancialReport.fulfilled]: (state, { payload }) => {
      state.tenantData = payload.data.tenantSummary;
      state.loading = false;
    },
    [fetchTenantsFinancialReport.rejected]: (state) => {
      state.loading = false;
    },
    // fetchTenantActivations
    [fetchTenantActivations.pending]: (state) => {
      state.loading = true;
    },
    [fetchTenantActivations.fulfilled]: (state, { payload }) => {
      state.tenantActivations = payload.data;
      state.loading = false;
    },
    [fetchTenantActivations.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export default slice.reducer;
