import Dexie from "dexie";
import { OFFLINE_DB_NAME, offline_tables } from "../../constants/OfflineDb";
import { AUTH } from "../../auth/FirebaseContext";

export const offlineDB = new Dexie(OFFLINE_DB_NAME);

export const createDB = () => {
  offlineDB.version(1).stores({
    all_clients: `
        uid,
        note,
        finance,
        disabled,
        contractTypeID,
        contractStartDate,
        contractID,
        city,
        address,
        ZIP,
        TIN,
        CIN,
        userUid
        `,
    sync_date: `
        date,
        userUid
        `
  });
  offlineDB.open();
};

export async function deleteAllClients() {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.all_clients);
    if (table) {
      const all = await table.filter(obj => obj.userUid === AUTH.currentUser.uid).toArray();
      for (const user of all) {
        await table.delete(user.uid);
      }
    }
  });
}

export async function insertSyncData(syncDate) {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.sync_date);
    if (table) {
      table.put({
        ...syncDate,
        userUid: AUTH.currentUser.uid
      });
    }
  });
}

export async function getLastSyncDate() {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.sync_date);
    if (table) {
      const all = await table.where("userUid").equals(AUTH.currentUser.uid).reverse().sortBy("date");
      return all[0];
    }
    return undefined;
  });
}

// CLIENTS
export async function getClientOffline(uid) {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.all_clients);
    if (table) {
      return table.filter(obj => obj.userUid === AUTH.currentUser.uid && obj.uid === uid).first();
    }
    return undefined;
  });
}

export async function getAllClientsOffline() {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.all_clients);
    if (table) {
      return table.filter(obj => obj.userUid === AUTH.currentUser.uid).toArray();
    }
    return [];
  });
}

export function insertClientOffline(client) {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.all_clients);
    if (table) {
      const lClient = await table.get(client.uid);
      if (!lClient) {
        table.put({
          ...client,
          userUid: AUTH.currentUser.uid
        });
      } else {
        table.update(client.uid, {
          ...client,
          userUid: AUTH.currentUser.uid
        });
      }
    }
  });
}


export function updateClientOffline(client) {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.all_clients);
    if (table) {
      table.update(client.uid, {
        ...client,
        userUid: AUTH.currentUser.uid
      });
    }
  });
}
