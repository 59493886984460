import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Card, Fab, Stack, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// utils
import { fCurrency } from "../../../utils/formatNumber";
// redux
import { useDispatch } from "../../../redux/store";
import { addToCart } from "../../../redux/slices/products";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/label";
import LazyImage from "../../../components/image/LazyImage";
// components


// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, images, price, discount, lager } = product;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const linkTo = PATH_DASHBOARD.shop.details(paramCase(name));

  const handleAddCart = async () => {
    const newProduct = {
      ...product,
      quantity: 1
    };
    try {
      dispatch(addToCart(newProduct));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickDetails = () => {
    navigate(linkTo, {
      state: product
    });
  };

  return (
    <Card
      sx={{
        "&:hover .add-cart-btn": {
          opacity: 1
        }
      }}>
      <Box sx={{ position: "relative", p: 1 }}>
        {lager === 0 && (
          <Label
            variant="filled"
            color={(lager === 0 && "error") || "info"}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: "absolute",
              textTransform: "uppercase"
            }}>
            Nema na stanju
          </Label>
        )}

        <Fab
          color="warning"
          size="medium"
          className="add-cart-btn"
          onClick={handleAddCart}
          sx={{
            right: 16,
            bottom: 16,
            zIndex: 9,
            opacity: 0,
            position: "absolute",
            transition: (theme) =>
              theme.transitions.create("all", {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter
              })
          }}>
          <Iconify icon="ic:round-add-shopping-cart" />
        </Fab>

        <LazyImage url={images === undefined ? undefined : images[0]} ratio="1/1" sx={{ borderRadius: 1.5 }} />
      </Box>

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Typography sx={{ cursor: "pointer" }} onClick={handleClickDetails} color="inherit" variant="subtitle2" noWrap>
          {name}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={0.5} sx={{ typography: "subtitle1" }}>
            {discount > 0 && (
              <Box component="span" sx={{ color: "text.disabled", textDecoration: "line-through" }}>
                {fCurrency(`${price}`)} RSD
              </Box>
            )}
            <Box component="span">
              {discount > 0 ?
                fCurrency(`${price - (price * discount / 100)}`)
                :
                fCurrency(`${price}`)
              }
              {` RSD`}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
