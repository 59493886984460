import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {onValue, orderByChild, query, ref} from "firebase/database";
import { RDB } from "../../auth/FirebaseContext";
import { assignPaymentToUserApi, deleteUserPaymentApi, findUsersForPaymentApi, uploadXMLPaymentsApi } from "../../api";

export const fetchAllPayments = createAsyncThunk('fetchAllPayments', async () => {
    const dbRef = ref(RDB, `bss/finance/payments/processed`);
    let rowNum = 0;
    await onValue(dbRef, (snapshot => {
        rowNum = snapshot.size
    }))
    let q = query(dbRef, orderByChild("status"));
    return await new Promise(async (resolve) => {
        await onValue(q, async (snapshot) => {
            let arr = [];
            snapshot.forEach(child => {
                let parsedChild = child.val();
                if (parsedChild.status) {
                    if (parsedChild.status === 2) {
                        arr.unshift({
                            id: child.key,
                            ...parsedChild
                        })
                    } else {
                        arr.push({
                            id: child.key,
                            ...parsedChild
                        })
                    }
                }
            })
            resolve({
                numberOfRows: rowNum,
                data: arr
            })
        });
    });
})

export const fetchAllUsersForPayment = createAsyncThunk('fetchAllUsersForPayment', async (fitid) => {
    return findUsersForPaymentApi({fitid: fitid});
})

export const assignPaymentToUser = createAsyncThunk('assignPaymentToUser', async (data) => {
    return assignPaymentToUserApi(data);
})

export const deleteUserPayment = createAsyncThunk('deleteUserPayment', async (data) => {
    return deleteUserPaymentApi(data);
})

export const manualAssignPayment = createAsyncThunk('manualAssignPayment', async (data) => {
    return assignPaymentToUserApi(data);
})

export const uploadXMLPayments = createAsyncThunk('uploadXMLPayments',async (data, { rejectWithValue }) => {
    try {
      const response = await uploadXMLPaymentsApi(data);
      return response;
    } catch (error) {
        console.log(error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

const initialState = {
    summary : null,
    payments: [],
    loading: [],
    loadingXML: false
}

export const slice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        resetSummary: state => {
            state.summary = null;
        },
        setXMLLoading: state => {
            state.loadingXML = true;
        }
    },
    extraReducers: {
        [fetchAllPayments.pending]: (state) => {
            state.loading = true
        },
        [fetchAllPayments.fulfilled]: (state, {payload}) => {
            state.payments = payload.data
            state.loading = false
        },
        [fetchAllPayments.rejected]: (state) => {
            state.loading = false
        },
        [uploadXMLPayments.pending]: (state) => {
            state.loadingXML = true
        },
        [uploadXMLPayments.fulfilled]: (state, {payload}) => {
            const newSummary = {
                count: payload.data.count,
                processed: payload.data.processed,
                notProcessed: payload.data.notProcessed,
                suspicious: payload.data.suspicious,
                existing: payload.data.existing
            }
            state.summary = newSummary
            state.loadingXML = false
        },
        [uploadXMLPayments.rejected]: (state) => {
            state.loadingXML = false
        },
        [deleteUserPayment.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.payments)]
            let index = arr.findIndex((payment) => payment.id === meta.arg.fitid)
            arr.splice(index, 1)
            state.payments = arr;
        },
        [manualAssignPayment.fulfilled]: (state, {meta}) =>{
            let arr = [...current(state.payments)]
            let index = arr.findIndex((payment) => payment.id === meta.arg.fitid)
            if(index !== -1){
                arr[index] = {
                    ...arr[index],
                    trnamt: Number(Number(arr[index].trnamt) - Number(meta.arg.amount))
                }
            }
        }
    }
});
export const {resetSummary, setXMLLoading} = slice.actions
export default slice.reducer;


