import { useState } from "react";
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { Button, MenuItem, Box } from "@mui/material";

import MenuPopover from "../../../components/menu-popover";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const OPTIONS = [
  { value: "featured", label: "Preporučeno" },
  { value: "newest", label: "Noviji" },
  { value: "priceDesc", label: "Cena: Veća - Manja" },
  { value: "priceAsc", label: "Cena: Manja - Veća" }
];

function renderLabel(label) {
  return {
    featured: "Preporučeno",
    newest: "Noviji",
    priceDesc: "Cena: Veća - Manja",
    priceAsc: "Cena: Manja - Veća"
  }[label];
}

// ----------------------------------------------------------------------

export default function ShopProductSort() {
  const { control } = useFormContext();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <Controller
      name="sortBy"
      control={control}
      render={({ field }) => (
        <>
          <Button
            disableRipple
            color="inherit"
            onClick={handleOpenPopover}
            endIcon={
              <Iconify icon={openPopover ? "eva:chevron-up-fill" : "eva:chevron-down-fill"} />
            }
            sx={{ fontWeight: "fontWeightMedium" }}>
            Sortiraj:
            <Box component="span" sx={{ color: "text.secondary", ml: 0.5 }}>
              {renderLabel(field.value)}
            </Box>
          </Button>

          <MenuPopover open={openPopover} onClose={handleClosePopover}>
            {OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === field.value}
                onClick={() => {
                  handleClosePopover();
                  field.onChange(option.value);
                }}>
                {option.label}
              </MenuItem>
            ))}
          </MenuPopover>
        </>
      )}
    />
  );
}
