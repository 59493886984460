import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Card, Container, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
// redux
import { dispatch, useSelector } from "../../../redux/store";
import { addToCart } from "../../../redux/slices/products";
// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// components
import Markdown from "../../../components/markdown";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import { SkeletonProductDetails } from "../../../components/skeleton";
// sections
import { ProductDetailsCarousel, ProductDetailsSummary } from "../components/details";
import CartWidget from "../components/CartWidget";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const SUMMARY = [
  {
    title: "100% Original",
    description: "Proverene i najbolje kase na tržištu",
    icon: "ic:round-verified"
  },
  {
    title: "10 dana povrat",
    description: "Ukoliko niste zadovoljni proizvodom, imate mogućnost da vratite u roku od 10 dana.",
    icon: "eva:clock-fill"
  },
  {
    title: "Garanacija",
    description: "Garnacija na kvalitet.",
    icon: "ic:round-verified-user"
  }
];

// ----------------------------------------------------------------------

export default function ProductDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const location = useLocation();
  const navigate = useNavigate();


  const [currentTab, setCurrentTab] = useState("description");
  const [loading, setLoading] = useState(true);
  const product = location.state;

  const { checkout } = useSelector((state) => state.products);

  useEffect(() => {
    if (!product) {
      navigate(PATH_DASHBOARD.shop.root);
    } else {
      setLoading(false);
    }
  }, [navigate, product]);

  const handleAddCart = (newProduct) => {
    dispatch(addToCart(newProduct));
  };

  const handleGotoStep = () => {

  };

  const TABS = [
    {
      value: "description",
      label: "description",
      component: product ? <Markdown children={product?.description} /> : null
    }
  ];

  return (
    <>
      <Helmet>
        <title>{`Prodavnica: ${product?.name || ""} | BSS`}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Detalji proizvoda"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Prodavnica",
              href: PATH_DASHBOARD.shop.root
            },
            { name: product?.name }
          ]}
        />

        <CartWidget totalItems={checkout.totalItems} />

        {product && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={7}>
                <ProductDetailsCarousel product={product} />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <ProductDetailsSummary
                  product={product}
                  cart={checkout.cart}
                  onAddCart={handleAddCart}
                  onGotoStep={handleGotoStep}
                />
              </Grid>
            </Grid>

            <Box
              gap={5}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)"
              }}
              sx={{ my: 10 }}>
              {SUMMARY.map((item) => (
                <Box key={item.title} sx={{ textAlign: "center" }}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: 64,
                      height: 64,
                      mx: "auto",
                      borderRadius: "50%",
                      color: "primary.main",
                      bgcolor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`
                    }}>
                    <Iconify icon={item.icon} width={36} />
                  </Stack>

                  <Typography variant="h6" sx={{ mb: 1, mt: 3 }}>
                    {item.title}
                  </Typography>

                  <Typography sx={{ color: "text.secondary" }}>{item.description}</Typography>
                </Box>
              ))}
            </Box>

            <Card>
              <Tabs
                value={currentTab}
                onChange={(event, newValue) => setCurrentTab(newValue)}
                sx={{ px: 3, bgcolor: "background.neutral" }}>
                {TABS.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>

              <Divider />

              {TABS.map(
                (tab) =>
                  tab.value === currentTab && (
                    <Box
                      key={tab.value}
                      sx={{
                        ...(currentTab === "description" && {
                          p: 3
                        })
                      }}>
                      {tab.component}
                    </Box>
                  )
              )}
            </Card>
          </>
        )}

        {loading && <SkeletonProductDetails />}
      </Container>
    </>
  );
}