
export const parseOperators = (operators) => {
    let parsed = [];
    if (!operators)
        return parsed;
    if (operators.length === 0)
        return parsed;
    Object.values(operators).forEach((value, index) => {
        parsed.push({
            ...value,
            uid: Object.keys(operators)[index],
            group: parseInt(value.group),
        })
    })
    return parsed;
}

export const parseLocations = (locations) => {
    let parsed = [];
    if (!locations)
        return parsed;
    if (locations.length === 0)
        return parsed;
    Object.values(locations).forEach((value, index) => {
        parsed.push({
            uid: Object.keys(locations)[index],
            cert: value.cert,
            JID: value.JID,
            key: value.key,
            name: value.name,
            PAC: value.PAC,
        })
    })
    return parsed;
}


export const parseVats = (vats) => {
    let parsed = [];
    if (!vats)
        return parsed;
    if (vats.length === 0)
        return parsed;
    Object.values(vats).forEach((value, index) => {
        parsed.push({
            uid: Object.keys(vats)[index],
            categoryType: value.categoryType,
            name: value.name,
            orderId: value.orderId,
            taxRates: value.taxRates,
        })
    })
    return parsed;
}