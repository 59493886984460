import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/path';
// components
import { useSettingsContext } from '../../../../components/settings';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import ProductNewEditForm from "./ProductNewEditForm";
// sections

// ----------------------------------------------------------------------

export default function ProductCreatePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> BSS - Kreiranje novog proizvoda</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Kreiranje novog proizvoda"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Lista proizvoda',
              href: PATH_DASHBOARD.admin.products.root,
            },
            { name: 'Nov proizvod' },
          ]}
        />
        <ProductNewEditForm />
      </Container>
    </>
  );
}
