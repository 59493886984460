import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    child,
    get,
    limitToFirst,
    onValue,
    orderByKey,
    push,
    query,
    ref as databaseRef,
    remove,
    set,
    startAfter,
    update
} from "firebase/database";
import {getDownloadURL, ref} from "firebase/storage";
import {AUTH, dbRef, RDB, storageRef} from "../../auth/FirebaseContext";
import {parseLocations, parseOperators, parseVats} from "../../utils/clientPreviewParsers";
import {
    changeNumberOfLicenceApi,
    deleteUserCertificate,
    findClient,
    getUserCardDetailsApi,
    insertLocation,
    invoiceCancellation,
    invoiceDeletion
} from "../../api";
import moment from "moment";
import {fDate} from "../../utils/formatTime";
import { getInvoiceAmount } from "../../utils/financeUtil";

export const postInvoiceCancellation = createAsyncThunk("postInvoiceCancellation", async ({ userFinance, ...payload }) => {
    await invoiceCancellation(payload);
    let fbFinance = await (await get(child(dbRef, `userFinance/${payload.userUID}`))).val();
    const invoicesBefore = [];
    const invoices = [];
    if (userFinance?.invoices) {
        Object.keys(userFinance.invoices).forEach(value => {
            let key = value;
            invoicesBefore.push({
                id: key,
                ...userFinance.invoices[key]
            });
        });
        if (fbFinance?.invoices) {
            await Promise.all(Object.keys(fbFinance.invoices).map(async value => {
                let key = value;
                let added = false;

                for (const invoice of invoicesBefore) {
                    if (invoice.id === key) {
                        const invoiceBef = fbFinance.invoices[key];
                        if (invoiceBef.state === 3 && invoiceBef.cancellationDate !== undefined) {
                            invoice.state = 3;
                            invoice.cancellationDate = invoiceBef.cancellationDate;
                        }
                        invoices.push(invoice);
                        added = true;
                        break;
                    }
                }
                if (!added) {
                    let invoice = await getInvoiceAmount(fbFinance.invoices[key]);
                    if (invoice!==null){
                        invoices.push({id: key,
                            ...invoice});
                    }
                }
            }));
        }
    } else if (fbFinance?.invoices) {
        Object.keys(fbFinance.invoices).forEach(value => {
            let key = value;
            invoices.push({
                id: key,
                ...fbFinance.invoices[key]
            });
        });
    }
    return {
        balance: fbFinance.balance,
        invoices
    };
})

export const postInvoiceDeletion = createAsyncThunk("postInvoiceDeletion", async ({ userFinance, ...payload }) => {
    await invoiceDeletion(payload);
    let fbFinance = await (await get(child(dbRef, `userFinance/${payload.userUID}`))).val();
    const invoicesBefore = [];
    const invoices = [];
    if (userFinance?.invoices) {
        Object.keys(userFinance.invoices).forEach(value => {
            let key = value;
            invoicesBefore.push({
                id: key,
                ...userFinance.invoices[key]
            });
        });
        if (fbFinance?.invoices) {
            await Promise.all(Object.keys(fbFinance.invoices).map(async value => {
                let key = value;
                let added = false;

                for (const invoice of invoicesBefore) {
                    if (invoice.id === key) {
                        const invoiceBef = fbFinance.invoices[key];
                        if (invoiceBef.state === 3 && invoiceBef.cancellationDate !== undefined) {
                            invoice.state = 3;
                            invoice.cancellationDate = invoiceBef.cancellationDate;
                        }
                        invoices.push(invoice);
                        added = true;
                        break;
                    }
                }
                if (!added) {
                    let invoice = await getInvoiceAmount(fbFinance.invoices[key]);
                    if (invoice!==null){
                        invoices.push({id: key,
                            ...invoice});
                    }
                }
            }));
        }
    } else if (fbFinance?.invoices) {
        Object.keys(fbFinance.invoices).forEach(value => {
            let key = value;
            invoices.push({
                id: key,
                ...fbFinance.invoices[key]
            });
        });
    }
    return {
        balance: fbFinance.balance,
        invoices
    };
})

export const fetchClientDetails = createAsyncThunk("fetchClientDetails", async (data) => {
    let {uid, user} = data;
    if (!user?.email) {
        try {
            const response = (await findClient({
                contractID: user.contractID
            })).data[0];
            if (response) {
                user = {
                    ...user,
                    email: response.email,
                    userFinance: response.userFinance
                };
                user.phoneNumber = response.phoneNumber;
                user.disabled = response.disabled;
            }
        } catch (e) {
            console.error("e", e);
        }
    }else{
        try {
            const response = (await findClient({
                email: user.email
            })).data[0];
            if (response) {
                user.phoneNumber = response.phoneNumber;
                user.disabled = response.disabled;
            }
        } catch (e) {
            console.error("e", e);
        }
    }
    let publicData = await (await get(child(dbRef, `users/${uid}/profile/pub`))).val();
    if (publicData.TIN) {
        const companyInfo = await (await get(child(dbRef, `/public/businesses/${publicData.TIN}`))).val();
        user = {
            ...user,
            ...companyInfo
        };
    }
    let contactEmails = [];
    let emails = await (await get(child(dbRef, `users/${uid}/protected/contactEmail`))).val();
    if (emails) {
        contactEmails = emails.split(",");
    }
    let finance = user.userFinance;
    let invoicesBefore = [];
    let invoices = [];
    if (finance) {
        if (finance?.invoices) {
            Object.keys(finance.invoices).forEach(value => {
                let key = value;
                invoicesBefore.push({
                    id: key,
                    ...finance.invoices[key]
                });
            });
        }
        let fbFinance = await (await get(child(dbRef, `userFinance/${uid}`))).val();
        if (fbFinance?.invoices) {
            await Promise.all(Object.keys(fbFinance.invoices).map(async value => {
                let key = value;
                let added = false;

                for (const invoice of invoicesBefore) {
                    if (invoice.id === key) {
                        const invoiceBef = fbFinance.invoices[key];
                        if (invoiceBef.state === 3 && invoiceBef.cancellationDate !== undefined) {
                            invoice.state = 3;
                            invoice.cancellationDate = invoiceBef.cancellationDate;
                        }
                        invoices.push(invoice);
                        added = true;
                        break;
                    }
                }
                if (!added) {
                    let invoice = await getInvoiceAmount(fbFinance.invoices[key]);
                    if (invoice!==null){
                        invoices.push({id: key,
                            ...invoice});
                    }
                }
            }));
        }
        finance = {
            balance: fbFinance.balance,
            invoices
        };
    }else{
        let fbFinance = await (await get(child(dbRef, `userFinance/${uid}`))).val();
        if (fbFinance?.invoices) {
            Object.keys(fbFinance.invoices).forEach(value => {
                let key = value;
                invoices.push({
                    id: key,
                    ...fbFinance.invoices[key]
                });
            });
        }
        finance = {
            balance: fbFinance.balance,
            invoices
        };
    }
    const imageRef = ref(ref(storageRef, "public/userphotos"), `${uid}.png`);
    let photoURL;
    try {
        photoURL = await getDownloadURL(imageRef);
    } catch (e) {
    }
    let operators = await (await get(child(dbRef, `users/${uid}/private/operators`))).val();
    let protectedData = await (await get(child(dbRef, `users/${uid}/protected`))).val();
    let settings = await (await get(child(dbRef, `users/${uid}/private/settings`))).val();
    let vats = await (await get(child(dbRef, `users/${uid}/private/vats`))).val();
    let posCardPayment = await (await get(child(dbRef, `users/${uid}/protected/products/cardPayment`))).val();
    posCardPayment = Object.entries(posCardPayment || {}).map(([key, value]) => {
        return {
            id : key,
            name: value.name,
            userHash: value.data.UserHash,
            state: "old"
        };
    });
    return {
        ...user,
        uid,
        photoURL,
        TIN: publicData.TIN,
        contractTypeID: protectedData.contractTypeID,
        contractID: protectedData.contractID,
        contractStartDate: protectedData.contractStartDate,
        contactName: protectedData.contactName,
        license: protectedData.license,
        operators: parseOperators(operators),
        locations: parseLocations(protectedData.se),
        // items: finalItems,
        eFacture: protectedData.efakture,
        vats: parseVats(vats),
        settings,
        contactEmails,
        finance,
        posCardPayment
    };
});

export const updateClientSettings = createAsyncThunk("updateClientSettings", async (data) => {
    const {settings, userUid} = data;
    await set(child(dbRef, `users/${userUid}/private/settings`), settings);
});

export const updateContactEmails = createAsyncThunk("updateContactEmails", async (data) => {
    const {userUid, emails} = data;
    return await set(child(dbRef, `users/${userUid}/protected/contactEmail`), emails);
});

export const insertNewOperator = createAsyncThunk("insertNewOperator", async (data) => {
    const {userUid, operator} = data;
    (await push(child(dbRef, `users/${userUid}/private/operators`), {
        username: operator.username,
        secret: operator.secret,
        cardNumber: operator.cardNumber || null,
        group: Number(operator.group),
        inactive: false
    }));
});

export const updateOperator = createAsyncThunk("updateOperator", async (data) => {
    const {userUid, operator} = data;
    await update(child(dbRef, `users/${userUid}/private/operators/${operator.uid}`), {
        username: operator.username,
        secret: operator.secret,
        cardNumber: operator.cardNumber || null,
        group: Number(operator.group),
        inactive: operator.inactive !== undefined ? !operator.inactive : false
    });
});

export const removeOperator = createAsyncThunk("removeOperator", async (data) => {
    const {userUid, uid} = data;
    await remove(child(dbRef, `users/${userUid}/private/operators/${uid}`));
});

export const insertNewLocation = createAsyncThunk("insertNewLocation", async (data) => {
    try {
        await insertLocation(data);
    } catch (e) {
        if (e?.response?.data) {
            throw new Error(e?.response?.data);
        }
        throw new Error(e);
    }
});

export const updateLocation = createAsyncThunk("", async (data) => {
    const {userUid, location} = data;
    await update(child(dbRef, `users/${userUid}/protected/se/${location.PAC}`), {
        name: location.name
    });
});

export const removeLocation = createAsyncThunk("removeLocation", async (data) => {
    return await deleteUserCertificate(data);
});

export const addLicense = createAsyncThunk("addLicense", async (data) => {
    const {userUid, license} = data;
    await set(child(dbRef, `users/${userUid}/protected/license/${license.productID}`), {
        count: Number(license.count),
        expiredDate: new Date(license.expiredDate).toISOString(),
        productID: Number(license.productID)
    });
});

export const updateNumberOfLicence = createAsyncThunk("updateNumberOfLicence", async (data) => {
    const {userUid, license, date} = data;
    const now = new Date();
    const isToday = fDate(now) === fDate(date);
    return await changeNumberOfLicenceApi({
        uid: userUid,
        id: license.productID,
        newVal: license.count,
        date: isToday ? null : new Date(date)
    })
});

export const changeContractType = createAsyncThunk("changeContractType", async (data) => {
    const {userUid, contractType, type, previous, changedBy, posData } = data;
    if (userUid === undefined)
        throw new Error("UserUid is required!");
    if (changedBy === undefined)
        throw new Error("changedBy is required!");
    if (previous === undefined)
        throw new Error("previous is required!");
    if (type !== "ESIR" && type !== "EFACTURE") {
        throw new Error("type is not valid!");
    }

    let cardPaymentObject = {}
    if (Array.isArray(posData) && posData.length > 0) {
      posData.forEach(item => {
        cardPaymentObject[item.id] = {
          data: {
            UserHash: item.userHash
          },
          name: item.name
        }
      })
      await set(
        child(dbRef, `users/${userUid}/protected/products/cardPayment`),
        cardPaymentObject
      )
    }

    let response;
    let path = `users/${userUid}/protected/contractTypeHistory/${moment().format("yyyy-MM-DDTHH:mm:ss")}`;
    if (type === "ESIR") {
        response = await set(child(dbRef, `users/${userUid}/protected/contractTypeID`), contractType);
    } else {
        response = await set(child(dbRef, `users/${userUid}/protected/efakture/contractTypeID`), contractType);
        path = `users/${userUid}/protected/efakture/contractTypeHistory/${moment().format("yyyy-MM-DDTHH:mm:ss")}`

    }
    await set(child(dbRef, path), {
        changedBy: changedBy,
        previousType: previous,
        changedTo: contractType
    });
    return response;
});

export const changePOSData = createAsyncThunk('changePOSData', async data => {
    const { userUid, posData } = data
    let cardPaymentObject = {}
    if (Array.isArray(posData)) {
      posData.forEach(item => {
        cardPaymentObject[item.id] = {
          data: {
            UserHash: item.userHash
          },
          name: item.name
        }
      })
    } else {
      console.error('Data is not an array:', posData)
      return
    }
    let response
    response = await set(
      child(dbRef, `users/${userUid}/protected/products/cardPayment`),
      cardPaymentObject
    )

    return response
  })

export const addItemsFromCsv = createAsyncThunk("addItemsFromCsv", async (payload) => {
    const {data, uid} = payload;
    await set(child(dbRef, `users/${uid}/private/items`), null);
    const newProducts = [];
    for (const item of data) {
        try {
            const response = await push(child(dbRef, `users/${uid}/private/items`), {
                uid: item.uid || null,
                name: item.name,
                category: item.category,
                vat: item.vat,
                unit: item.unit,
                price: Number(item.price),
                ean: item.ean || null,
                code: item.code
            });
            newProducts.push({
                ...item,
                uid: response.key
            });
        } catch (e) {
        }
    }
    return newProducts;
});

export const fetchUserProducts = createAsyncThunk("fetchUserProducts", async (data) => {
    const {clientUid, lastData, rowsPerPage} = data;
    const privateItemsRef = databaseRef(RDB, `users/${clientUid}/private/items`);
    const total = await new Promise((resolve) => {
        get(privateItemsRef).then(snapshot => {
            return resolve(snapshot.size);
        }).catch(reason => {
            console.error("reason", reason);
            return resolve(0);
        });
    });
    let q;
    if (lastData) {
        q = query(privateItemsRef, orderByKey(), limitToFirst(rowsPerPage), startAfter(lastData + 1));
    } else {
        q = query(privateItemsRef, orderByKey(), limitToFirst(rowsPerPage));
    }
    let items = await new Promise(async (resolve, reject) => {
        let arr = [];
        try {
            await onValue(q, async (snapshot) => {
                let snapshotSize = snapshot.size;
                if (snapshotSize === 0) {
                    return resolve(arr);
                } else {
                    let i = 0;
                    snapshot.forEach(child => {
                        arr.push({
                            ...child.val(),
                            uid: child.key
                        });
                        if (i === snapshotSize - 1) {
                            return resolve(arr);
                        }
                        i = i + 1;
                    });
                }
            });
        } catch (e) {
            return reject(e);
        }
    });
    let finalItems = [];
    for (const item of items) {
        let publicItem = (await get(child(dbRef, `public/items/${item.uid}`))).val();
        finalItems.push({
            ...publicItem,
            ...item
        });
    }
    return {
        items: finalItems,
        total: total
    };
});
export const getUserCardDetails = createAsyncThunk("getUserCardDetails", async (data) => {
    return await getUserCardDetailsApi(data);
});

const initialState = {
    details: {
        settings: undefined,
        operators: [],
        vats: [],
        locations: [],
        license: [],
        contactEmails: []
    },
    products: {
        totalRows: 0,
        content: []
    },
    loadingProducts: false,
    updating: false,
    loading: false
};

const slice = createSlice({
    name: "clientPreview",
    initialState,
    reducers: {},
    extraReducers: {
        [postInvoiceCancellation.fulfilled]: (state, {payload}) => {
            state.details = {
                ...state.details,
                finance: payload
            }
        },
        //postInvoiceDeletion
        [postInvoiceDeletion.fulfilled]: (state, {payload}) => {
            state.details = {
                ...state.details,
                finance: payload
            }
        },
        [fetchClientDetails.pending]: (state) => {
            state.loading = true;
        },
        [fetchClientDetails.fulfilled]: (state, {payload}) => {
            state.details = payload;
            state.loading = false;
        },
        [fetchClientDetails.rejected]: (state) => {
            state.loading = false;
        },
        //updateClientSettings
        [updateClientSettings.pending]: (state) => {
            state.loading = true;
        },
        [updateClientSettings.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            state.details = {
                ...details,
                settings: meta.arg.settings
            };
            state.loading = false;
        },
        [updateClientSettings.rejected]: (state) => {
            state.loading = false;
        },
        //updateContactEmails
        [updateContactEmails.pending]: (state) => {
            state.loading = true;
        },
        [updateContactEmails.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            state.details = {
                ...details,
                contactEmails: meta.arg.emails!==null?meta.arg.emails.split(","):[]
            };
            state.loading = false;
        },
        [updateContactEmails.rejected]: (state) => {
            state.loading = false;
        },
        //insertNewOperator
        [insertNewOperator.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            const operators = [...details.operators];
            operators.push({
                ...meta.arg.operator,
                group: parseInt(meta.arg.operator.group),
                inactive: false
            });
            state.details = {
                ...details,
                operators
            };
        },
        //updateOperator
        [updateOperator.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            const operators = [...details.operators];
            const updated = meta.arg.operator;
            const index = operators.findIndex(op => op.uid === updated.uid);
            if (index !== -1) {
                operators[index] = {
                    ...operators[index],
                    ...updated,
                    group: parseInt(updated.group),
                    inactive: updated.inactive ? updated.inactive : false
                };
            }
            state.details = {
                ...details,
                operators
            };
        },
        //removeOperator
        [removeOperator.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            const operators = [...details.operators];
            const index = operators.findIndex(op => op.uid === meta.arg.uid);
            operators.splice(index, 1);
            state.details = {
                ...details,
                operators
            };
        },
        //insertNewLocation
        [insertNewLocation.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            const locations = [...details.locations];
            locations.push(meta.arg);
            state.details = {
                ...details,
                locations
            };
        },
        //removeLocation
        [removeLocation.pending]: (state) => {
            state.updating = true;
        },
        [removeLocation.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            const locations = [...details.locations];
            const index = locations.findIndex(op => op.uid === meta.arg.uid);
            locations.splice(index, 1);
            state.details = {
                ...details,
                locations
            };
            state.updating = false;
        },
        [removeLocation.rejected]: (state) => {
            state.updating = false;
        },
        //updateNumberOfLicence
        [updateNumberOfLicence.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            let license = [...details.license];
            let index = license.findIndex(obj => obj.productID === meta.arg.productID);
            if (index !== -1) {
                license[index] = {
                    ...license[index],
                    count: meta.arg.count
                };
            }
            state.details = {
                ...details,
                license
            };
        },
        //addLicense
        [addLicense.fulfilled]: (state, {meta}) => {
            let details = {...current(state.details)};
            let license = [...details.license];
            license.push(meta.license);
            state.details = {
                ...details,
                license
            };
        },
        [fetchUserProducts.pending]: (state) => {
            state.loadingProducts = true;
        },
        [fetchUserProducts.fulfilled]: (state, {payload}) => {
            let products = {...current(state.products)};
            let content = [...products.content];
            for (const item of payload.items) {
                const index = content.findIndex(lItem => lItem.uid === item.uid);
                if (index === -1) {
                    content.push(item);
                }
            }
            state.products = {
                ...products,
                content: content,
                totalRows: payload.total
            };
            state.loadingProducts = false;
        },
        [fetchUserProducts.rejected]: (state) => {
            state.loadingProducts = false;
        },
        //changeContractType
        [changeContractType.fulfilled]: (state, {meta}) => {
            let details = {
                ...current(state.details)
            };
            if (meta.arg.posData.length > 0) {
                details.posCardPayment = meta.arg.posData.map(item => {
                    return {
                        ...item,
                        state: "old"
                    }
                });
            }
            let eFacture = details.eFacture;
            const type = meta.arg.type;
            if (type === "ESIR") {
                details.contractTypeID = meta.arg.contractType;
            } else {
                eFacture = {
                    ...eFacture,
                    contractTypeID: meta.arg.contractType
                };
            }
            state.details = {
                ...details,
                eFacture
            };
        },
        //changePOSData
        [changePOSData.fulfilled]: (state, {meta}) => {
            let details = {
                ...current(state.details)
            };
            details.posCardPayment = meta.arg.posData.map(item => {
                return {
                    ...item,
                    state: "old"
                }
            });
            state.details = details;
        },
        //addItemsFromCsv
        [addItemsFromCsv.pending]: (state) => {
            state.loadingProducts = true;
        },
        [addItemsFromCsv.fulfilled]: (state, {payload}) => {
            let products = {...current(state.products)};
            state.products = {
                ...products,
                content: payload,
                totalRows: payload.length
            };
            state.loadingProducts = false;
        },
        [addItemsFromCsv.rejected]: (state) => {
            state.loadingProducts = false;
        }
    }
});
export const {changeFinance} = slice.actions
export default slice.reducer;
