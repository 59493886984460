import PropTypes from "prop-types";
// @mui
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  query: PropTypes.string,
  isChat: PropTypes.bool,
  sx: PropTypes.object
};

export default function SearchNotFound({ query, isChat = false, sx, ...other }) {
  return query ? (
    <Paper
      sx={{
        textAlign: "center",
        ...sx
      }}
      {...other}>
      <Typography variant="h6" paragraph>
        Nema rezultata pretrage
      </Typography>

      <Typography variant="body2">
        Nema pronađenih reuzultata pretrage za &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> Molimo Vas proverite da li je unos ispravan.
        <br/> {isChat && "Možda ovaj korisnik ne koristi naše rešenje?"}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      Unesite ključne reči
    </Typography>
  );
}
