// @mui
import { Box, Stack, Tooltip, Typography } from "@mui/material";
// hooks
import { useAuthContext } from "../../../auth/useAuthContext";
// layouts
import LoginLayout from "../../../layouts/login";
import AuthLoginForm from "./AuthLoginForm";

// ----------------------------------------------------------------------

export default function LoginAuth0() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">PRIJAVITE SE</Typography>
        <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: "absolute", right: 0 }}
          />
        </Tooltip>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
