import {useEffect, useLayoutEffect, useState} from "react";
import {Outlet} from "react-router-dom";
// @mui
import {Box} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import {useSettingsContext} from "../../components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import {dispatch} from "../../redux/store";
import {clientsSync} from "../../redux/slices/clients";
import NavHorizontal from "./nav/NavHorizontal";
import {AUTH} from "../../auth/FirebaseContext";
import {notificationsListener} from "../../helper/firestore/listeners";
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  useEffect(() => {
    if(AUTH.currentUser?.uid){
        notificationsListener()
    }
  },[])

  useLayoutEffect(() => {
    if(AUTH.currentUser?.uid){
      dispatch(clientsSync()).unwrap().catch(reason => {
        console.error("clientsSync", reason);
      });
    }
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 }
          }}>
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 }
        }}>
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
