import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
// _mock
// components
import Label from "../../../../../components/label";
import Iconify from "../../../../../components/Iconify";
//
import CheckoutSummary from "../CheckoutSummary";
import CheckoutBillingNewAddressForm from "./CheckoutBillingNewAddressForm";
import { deleteAddress, getSavedAddress } from "../../../../../helper/session";

// ----------------------------------------------------------------------

CheckoutBillingAddress.propTypes = {
  checkout: PropTypes.object,
  onBackStep: PropTypes.func,
  onCreateBilling: PropTypes.func
};

export default function CheckoutBillingAddress({ checkout, onBackStep, onCreateBilling }) {
  const { total, discount, subtotal } = checkout;

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(getSavedAddress());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAddress = (index) => {
    deleteAddress(index);
    const arr = [...address];
    arr.splice(index, 1);
    setAddress(arr);
  };


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {address.map((address, index) => (
            <AddressItem
              key={`checkOut-address-${index}`}
              address={address}
              onDelete={() => handleDeleteAddress(index)}
              onCreateBilling={() => onCreateBilling(address)}
            />
          ))}

          <Stack direction="row" justifyContent="space-between">
            <Button
              size="small"
              color="inherit"
              onClick={onBackStep}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}>
              Nazad
            </Button>

            <Button
              size="small"
              variant="soft"
              onClick={handleOpen}
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Dodaj novu adresu
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutSummary subtotal={subtotal} total={total} discount={discount} />
        </Grid>
      </Grid>

      <CheckoutBillingNewAddressForm
        open={open}
        onClose={handleClose}
        onCreateBilling={onCreateBilling}
      />
    </>
  );
}

// ----------------------------------------------------------------------

AddressItem.propTypes = {
  address: PropTypes.object,
  onCreateBilling: PropTypes.func,
  onDelete: PropTypes.func
};

function AddressItem({ address, onCreateBilling, onDelete }) {
  const { name, lastname, fullAddress, email, phoneNumber, isDefault } = address;

  return (
    <Card
      sx={{
        p: 3,
        mb: 3
      }}>
      <Stack
        spacing={2}
        alignItems={{
          md: "flex-end"
        }}
        direction={{
          xs: "column",
          md: "row"
        }}>
        <Stack flexGrow={1} spacing={1}>
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1">
              {`${name} ${lastname}`}
              <Box component="span" sx={{ ml: 0.5, typography: "body2", color: "text.secondary" }}>
                ({email})
              </Box>
            </Typography>

            {isDefault && (
              <Label color="info" sx={{ ml: 1 }}>
                Default
              </Label>
            )}
          </Stack>

          <Typography variant="body2">{fullAddress}</Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {phoneNumber}
          </Typography>
        </Stack>

        <Stack flexDirection="row" flexWrap="wrap" flexShrink={0}>
          <Button onClick={onDelete} variant="outlined" size="small" color="inherit" sx={{ mr: 1 }}>
            Obriši
          </Button>

          <Button variant="outlined" size="small" onClick={onCreateBilling}>
            Dostavi na ovu adresu
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
