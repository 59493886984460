import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import { child, get, push, set } from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import moment from "moment";


export const addNotificationOrderForAdmin = createAsyncThunk('addNotificationOrderForAdmin', async () => {
    const data ={
        body: "Imate novu porudžbenicu.",
        date: moment().format("yyyy-MM-DD"),
        isRead: false,
        title: "Nova porudžbenica"
    }
    const response = await push(child(dbRef, `users/IIaf85YIvkUPKNGQtX0Au4G7IyF2/private/messages/inbox/`), data)
    return {
        ...data,
        uid: response.key,
    };
})
export const fetchAllUserMessages = createAsyncThunk('fetchAllUserMessages', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`))).forEach(child => {
        let childValue = child.val();
        arr.push({
            uid: child.key,
            ...childValue
        });
    });
    return arr;
})

export const setNotificationAsRead = createAsyncThunk('setNotificationAsRead', async (data) => {
    return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${data.uid}`), {
        body: data.body,
        date: data.date,
        isRead: true,
        title: data.title
    })
})

const initialState = {
    messages: [],
}

export const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index === -1) {
                    arr.push(payload)
                    state.messages = arr;
                }
            }
        },
        changeNotification: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr[index] = payload;
                    state.messages = arr;
                }
            }
        },
        deleteNotification: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr.splice(index, 1)
                    state.messages = arr;
                }
            }
        }
    },
    extraReducers: {
        [addNotificationOrderForAdmin.fulfilled]: (state, {payload}) => {
            let arr = [...current(state).messages]
            arr.push(payload);
            state.messages = arr
        },
        [addNotificationOrderForAdmin.rejected]: (state, action) => {
            console.error("addNotificationOrderForAdmin", action)
        },
        [fetchAllUserMessages.fulfilled]: (state, {payload}) => {
            state.messages = payload
        },
        [setNotificationAsRead.fulfilled]: (state, {meta}) => {
            let newData = {
                ...meta.arg,
                isRead: true
            };
            let arr = [...current(state).messages]
            let index = arr.findIndex((notification) => notification.uid === newData.uid)
            if (index !== -1) {
                arr[index] = newData;
            }
            state.messages = arr;
        }
    }
});
export const {addNotification, changeNotification, deleteNotification} = slice.actions
export default slice.reducer;


