import PropTypes from "prop-types";
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
// components
import Image from "../../../../../components/image";
import Iconify from "../../../../../components/Iconify";
// section

// ----------------------------------------------------------------------

CheckoutPaymentMethods.propTypes = {
  cardOptions: PropTypes.array,
  paymentOptions: PropTypes.array
};

export default function CheckoutPaymentMethods({ paymentOptions, ...other }) {
  const { control } = useFormContext();

  return (
    <>
      <Card {...other}>
        <CardHeader title="Način plaćanja" />

        <CardContent>
          <Controller
            name="payment"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <RadioGroup row {...field}>
                  <Stack spacing={3} sx={{ width: 1 }}>
                    {paymentOptions.map((option) => (
                      <PaymentOption
                        key={option.title}
                        option={option}
                        hasChild={option.value === "credit_card"}
                        isSelected={field.value === option.value}
                        isCreditMethod={
                          option.value === "credit_card" && field.value === "credit_card"
                        }
                      />
                    ))}
                  </Stack>
                </RadioGroup>

                {!!error && (
                  <FormHelperText error sx={{ pt: 1, px: 2 }}>
                    {error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </CardContent>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

PaymentOption.propTypes = {
  onOpen: PropTypes.func,
  hasChild: PropTypes.bool,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
  cardOptions: PropTypes.array,
  isCreditMethod: PropTypes.bool
};

function PaymentOption({ option, hasChild, isSelected}) {
  const { value, title, icons, description } = option;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        transition: (theme) => theme.transitions.create("all"),
        ...(isSelected && {
          boxShadow: (theme) => theme.customShadows.z20
        }),
        ...(hasChild && {
          flexWrap: "wrap"
        })
      }}
    >
      <FormControlLabel
        value={value}
        control={<Radio checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} />}
        label={
          <Box sx={{ ml: 1 }}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {description}
            </Typography>
          </Box>
        }
        sx={{ py: 3, pl: 2.5, flexGrow: 1, mr: 0 }}
      />

      <Stack
        direction="row"
        spacing={1}
        flexShrink={0}
        sx={{
          pr: 2.5,
          display: {
            xs: "none",
            sm: "inline-flex"
          }
        }}>
        {icons.map((icon) => (
          <Image key={icon} disabledEffect alt="logo card" src={icon} />
        ))}
      </Stack>
    </Paper>
  );
}
