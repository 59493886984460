import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
// @mui
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
// utils
import { bgGradient } from "../../../../utils/cssStyles";
// components
import Carousel, { CarouselArrowIndex } from "../../../../components/carousel";
import LazyImage from "../../../../components/image/LazyImage";

// ----------------------------------------------------------------------

const THUMB_SIZE = 64;

const StyledThumbnailsContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "length"
})(({ length, theme }) => ({
  margin: theme.spacing(0, "auto"),
  position: "relative",

  "& .slick-slide": {
    opacity: 0.48,
    "&.slick-current": {
      opacity: 1
    },
    "& > div": {
      padding: theme.spacing(0, 0.75)
    }
  },

  ...(length === 1 && {
    maxWidth: THUMB_SIZE + 16
  }),
  ...(length === 2 && {
    maxWidth: THUMB_SIZE * 2 + 32
  }),
  ...((length === 3 || length === 4) && {
    maxWidth: THUMB_SIZE * 3 + 48
  }),
  ...(length >= 5 && {
    maxWidth: THUMB_SIZE * 6
  }),
  ...(length > 2 && {
    "&:before, &:after": {
      ...bgGradient({
        direction: "to left",
        startColor: `${alpha(theme.palette.background.default, 0)} 0%`,
        endColor: `${theme.palette.background.default} 100%`
      }),
      top: 0,
      zIndex: 9,
      content: "''",
      height: "100%",
      position: "absolute",
      width: (THUMB_SIZE * 2) / 3
    },
    "&:after": {
      right: 0,
      transform: "scaleX(-1)"
    }
  })
}));

// ----------------------------------------------------------------------

ProductDetailsCarousel.propTypes = {
  product: PropTypes.object
};

export default function ProductDetailsCarousel({ product }) {
  const theme = useTheme();

  const carousel1 = useRef(null);

  const carousel2 = useRef(null);

  const [nav1, setNav1] = useState();

  const [nav2, setNav2] = useState();

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = product.images !== undefined ? product.images : [];


  const carouselSettings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  const carouselSettings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: "0px",
    slidesToShow: images.length > 3 ? 3 : images.length
  };

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current);
    }
    if (carousel2.current) {
      setNav2(carousel2.current);
    }
  }, []);

  useEffect(() => {
    carousel1.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  const handlePrev = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      setCurrentIndex(images.length -1);
    } else {
      setCurrentIndex(prevIndex);
    }
    carousel2.current?.slickPrev();
  };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= images.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(nextIndex);
    }
    carousel2.current?.slickNext();
  };

  const renderLargeImg = (
    <Box sx={{ mb: 3, borderRadius: 2, overflow: "hidden", position: "relative" }}>
      <Carousel {...carouselSettings1} asNavFor={nav2} ref={carousel1}>
        {images.map((img, index) => (
          <LazyImage
            key={`large-img-${img}-${index}`}
            url={img}
            ratio="1/1"
            sx={{ cursor: "zoom-in" }}
          />
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={currentIndex}
        total={images.length}
        onNext={handleNext}
        onPrevious={handlePrev}
      />
    </Box>
  );

  const renderThumbnails = (
    <StyledThumbnailsContainer length={images.length}>
      <Carousel {...carouselSettings2} asNavFor={nav1} ref={carousel2}>
        {images.map((img, index) => (
          <LazyImage
            key={`thumb-img-${img}-${index}`}
            url={img}
            alt="thumbnail"
            sx={{
              width: THUMB_SIZE,
              height: THUMB_SIZE,
              borderRadius: 1.5,
              cursor: "pointer",
              ...(currentIndex === index && {
                border: `solid 2px ${theme.palette.primary.main}`
              })
            }}
          />
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <Box
      sx={{
        "& .slick-slide": {
          float: theme.direction === "rtl" ? "right" : "left"
        }
      }}>
      {renderLargeImg}

      {renderThumbnails}
    </Box>
  );
}
