import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Button, Card, CardHeader, Grid, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../../routes/path";
// components
import Iconify from "../../../../../components/Iconify";
import EmptyContent from "../../../../../components/empty-content";
//
import CheckoutSummary from "../CheckoutSummary";
import CheckoutCartProductList from "./CheckoutCartProductList";

// ----------------------------------------------------------------------

CheckoutCart.propTypes = {
  checkout: PropTypes.object,
  onNextStep: PropTypes.func,
  onDeleteCart: PropTypes.func,
  onApplyDiscount: PropTypes.func,
  onDecreaseQuantity: PropTypes.func,
  onIncreaseQuantity: PropTypes.func
};

export default function CheckoutCart({
                                       checkout,
                                       onNextStep,
                                       onDeleteCart,
                                       onIncreaseQuantity,
                                       onDecreaseQuantity
                                     }) {
  const { cart, total, discount, subtotal } = checkout;

  const totalItems = sum(cart.map((item) => item.quantity));

  const isEmptyCart = !cart.length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title={
              <Typography variant="h6">
                Korpa
                <Typography component="span" sx={{ color: "text.secondary" }}>
                  &nbsp;({totalItems} {totalItems > 1 ? "proizvoda" : "proizvod"})
                </Typography>
              </Typography>
            }
            sx={{ mb: 3 }}
          />

          {!isEmptyCart ? (
            <CheckoutCartProductList
              products={cart}
              onDelete={onDeleteCart}
              onIncreaseQuantity={onIncreaseQuantity}
              onDecreaseQuantity={onDecreaseQuantity}
            />
          ) : (
            <EmptyContent
              title="Korpa je prazna"
              description="Trenutno nemate proizvode u korpi"
              img="/assets/illustrations/illustration_empty_cart.svg"
            />
          )}
        </Card>

        <Button
          to={PATH_DASHBOARD.shop.root}
          component={RouterLink}
          color="inherit"
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}>
          Nastavi kupovinu
        </Button>
      </Grid>

      <Grid item xs={12} md={4}>
        <CheckoutSummary
          total={total}
          discount={discount}
          subtotal={subtotal}
        />
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!cart.length}
          onClick={onNextStep}>
          Naplati
        </Button>
      </Grid>
    </Grid>
  );
}
