import PropTypes from "prop-types";
import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// locales
// auth
//
import MotionContainer from "../../animate/MotionContainer";
import { varBounce } from "../../animate";
import ForbiddenIllustration from "../../../assets/illustratuions/ForbiddenIllustration";

// ----------------------------------------------------------------------

const NavItem = () => {
  return <Container component={MotionContainer} sx={{ textAlign: "center" }}>
    <m.div variants={varBounce().in}>
      <Typography variant="h3" paragraph>
        Permission Denied
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography sx={{ color: "text.secondary" }}>You do not have permission to access this page</Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
    </m.div>
  </Container>;
};

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool
};

export default NavItem;
